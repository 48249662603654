import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { changePasswordUser } from "../../features/auth/authSlice";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0, 3, 0)
}));

const StyledInputLabel = styled("label")(({ theme, styledColors }) => ({
  position: "absolute",
  top: "-12px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors?.color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.background
    },
    "&:hover fieldset": {
      borderColor: styledColors?.color
    },
    "&.Mui-focused fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors?.color
    }
  }
}));

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const styles = useThemeColor();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  useEffect(() => {
    if (!email || !token) {
      navigate("/login");
    }
  });
  const [formErrors, setFormErrors] = useState({
    newPassword: false,
    confirmPassword: false
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === "newPassword") {
      setFormErrors({ ...formErrors, newPassword: !validatePassword(value) });
    } else if (name === "confirmPassword") {
      setFormErrors({
        ...formErrors,
        confirmPassword: value !== formData.newPassword
      });
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    const { payload } = await dispatch(
      changePasswordUser({ email, token, password: formData.newPassword })
    );
    if (!payload.error) {
      setSnackbarMessage("Success! You can now login with your new password.");
      setOpenSnackbar(true);
      setTimeout(() => navigate("/login"), 3000);
    } else {
      setSnackbarMessage(payload.error || "Reset Password failed.");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display={"flex"}
      width="100%"
      overflow="hidden"
      height="100dvh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </Box>

      <Box
        flexGrow={1}
        paddingBottom={4}
        paddingLeft={10}
        sx={{
          paddingRight: { xs: 5, md: 13, lg: 30 },
          paddingLeft: { xs: 5, md: 10 },
          paddingTop: { xs: 5, md: 17 }
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          display={"flex"}
          sx={{
            justifyContent: { xs: "initial", md: "space-between" }
          }}
          flexDirection="column"
          height="100%"
        >
          <Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "block"
                },
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Typography fontSize="30px" fontWeight="bold" gutterBottom>
                Change Password
              </Typography>
              {/* <Typography color="#6B7280" fontSize="16px" gutterBottom>
                Enter New password
              </Typography> */}
            </Box>
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={1000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={
                  snackbarMessage.includes("Success") ? "success" : "error"
                }
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Box component={"form"} mt={8} onSubmit={resetPassword}>
              <StyledTextFieldContainer>
                <StyledInputLabel styledColors={styles} htmlFor="newPassword">
                  New Password
                </StyledInputLabel>
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  required
                  error={formErrors.newPassword}
                  helperText={
                    formErrors.newPassword &&
                    "Password must be 8 characters long: Contains 1 upper, 1 lower case, 1 symbol and 1 digit"
                  }
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer>
                <StyledInputLabel
                  styledColors={styles}
                  htmlFor="confirmPassword"
                >
                  Re-enter Password
                </StyledInputLabel>
                <StyledTextField
                  styledColors={styles}
                  fullWidth
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  placeholder="Re-enter Password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                  error={formErrors.confirmPassword}
                  helperText={
                    formErrors.confirmPassword &&
                    "Password doesn't match with new password"
                  }
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                style={{
                  marginTop: 22,
                  backgroundColor: "#38B65B",
                  color: "#FCFCFC",
                  width: "100%",
                  height: "42px",
                  textTransform: "none"
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Change password"}
              </Button>
            </Box>
          </Box>
          <Box
            mt={6}
            display="flex"
            gap="20px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Remember Password</Typography>
            <Link
              to="/login"
              variant="outlined"
              style={{
                color: "#38B65B",
                textDecoration: "none"
              }}
            >
              Sign In
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
