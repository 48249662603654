import React, { useCallback, useEffect, useState } from "react";
import { generateSignedUrl } from "../../../utils/getPreSignedURL";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Typography
} from "@mui/material";
import AudioPlayer from "../AudioPlayer";
import useSignedUrl from "../../../utils/useSignedUrl";
import useSanitizeContent from "../../../utils/useSanitizeContent";
import "./RenderHtml.css";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  completeTrainings,
  updatePercentage,
  updateTrainingById
} from "../../../features/training/trainingSlice";
import { selectLevelData } from "../../../features/training/trainingSelectors";
import useThemeColor from "../../../utils/useTheme";
import { updateUserUncompletedCount } from "../../../features/auth/authSlice";
import { Help } from "@mui/icons-material";
import FAQModal from "../../Modals/FAQModal";

const AudioItem = ({ audio, setIsAudioFinished }) => {
  const src = useSignedUrl(
    audio,
    process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
  );
  const styles = useThemeColor();
  return (
    <Box
      sx={{
        background: styles.inputLabelColor,
        width: { sm: "100%", md: "35%" }
      }}
      p={"8px 20px"}
      borderRadius={"100px"}
    >
      <AudioPlayer
        setIsAudioFinished={setIsAudioFinished}
        styles={styles}
        isSliderNeeded
        src={src}
      />
    </Box>
  );
};
const isValidUrl = (url) => {
  return /^(https?:\/\/|www\.)/.test(url);
};
const RenderHtml = ({
  activeData,
  styles,
  activeDay,
  setIsAudioFinished,
  isAudioFinished,
  isMobile,
  selectedLevel,
  currentLevel,
  activeLevelForMobile
}) => {
  const [value, setValue] = useState("");
  const availableLevels = useSelector(selectLevelData);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openFAQModal, setOpenFAQModal] = useState(false);
  const updateImageSources = useCallback(
    async (html, color) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const images = Array.from(doc.querySelectorAll("img"));

      for (let img of images) {
        const fileName = img.getAttribute("src"); // Assuming the src contains the filename

        if (fileName && !fileName.startsWith("http")) {
          try {
            const signedUrl = await generateSignedUrl(
              fileName,
              process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
            );

            // Replace filename with the signed URL in the img tag
            img.src = signedUrl;
          } catch (error) {
            console.error("Error generating signed URL:", error);
          }
        }
        if (!img.hasAttribute("width")) {
          img.setAttribute("width", "100%");
        }
        if (!img.hasAttribute("height")) {
          img.setAttribute("height", "100%");
        }
      }

      const links = Array.from(doc.querySelectorAll("a"));
      links.forEach((link) => {
        link.target = "_blank";
        let href = link.getAttribute("href");
        link.style.background = ""; // Clear background
        link.style.color = ""; //
        if (href) {
          if (href.startsWith("www.")) {
            link.setAttribute("href", `https://${href}`);
          } else if (!isValidUrl(href)) {
            // Optionally prepend a default protocol (http or https)
            link.setAttribute("href", `https://${href}`);
          }
        }
      });
      const paragraphs = [
        ...Array.from(doc.querySelectorAll("span")),
        ...Array.from(doc.querySelectorAll("strong")),
        ...Array.from(
          doc.querySelectorAll(["h1", "h2", "h3", "h4", "h5", "h6"])
        )
      ];
      paragraphs.forEach((p) => {
        p.style.background = ""; // Clear background
        p.style.color = color; // Clear color
      });

      const allParas = Array.from(doc.querySelectorAll("p"));
      let consecutiveJustifyBrs = []; // Array to track sequences of <p> elements

      allParas.forEach((p, index) => {
        // Check if it's a target <p> element
        if (
          p.classList.contains("ql-align-justify") &&
          p.innerHTML.trim() === "<br>"
        ) {
          consecutiveJustifyBrs.push(p);
        } else {
          // If the sequence ends, check its length and remove if needed
          if (consecutiveJustifyBrs.length > 7) {
            const lastFourElements = consecutiveJustifyBrs.slice(-4);
            lastFourElements.forEach((element) => element.remove());
          }
          // Reset the sequence tracking array
          consecutiveJustifyBrs = [];
        }
      });

      // Handle any remaining sequence at the end of the loop
      if (consecutiveJustifyBrs.length > 7) {
        const lastFourElements = consecutiveJustifyBrs.slice(-4);
        lastFourElements.forEach((element) => element.remove());
      }
      // Set the updated HTML back to the editor
      setValue(doc.body.innerHTML);
    },
    [setValue]
  );
  useEffect(() => {
    const initialHtml =
      activeData?.generalText || "<p>No content available.</p>";
    updateImageSources(initialHtml, styles.color);
  }, [updateImageSources, activeDay, activeData?.generalText]);
  const sanitizedContent = useSanitizeContent(value);
  const dispatch = useDispatch();

  const onComplete = async () => {
    try {
      const completeResult = await dispatch(
        completeTrainings({
          date: dayjs().format("YYYY-MM-DD"),
          levelId: activeData?.levelId,
          trainingDayId: activeData?.trainingId
        })
      );
      if (completeResult?.payload?.missedDays?.length) {
        setSnackbarMessage(
          `Please complete all prior trainings before this day. Missed Days: ${completeResult?.payload?.missedDays.join(
            ", "
          )}.`
        );
        setOpenSnackbar(true);
        return;
      }
      // Check if the first dispatch was rejected
      if (completeResult.meta.requestStatus === "rejected") {
        throw new Error("completeTrainings failed");
      }

      // Only run this if the first dispatch succeeded
      await dispatch(
        updateTrainingById({
          id: activeData?.trainingId,
          updates: { completedOn: new Date() }
        })
      );

      const levelData = availableLevels?.find(
        (item) => item.levelId === activeData?.levelId
      );

      const updatedPercent =
        ((levelData?.completedTrainingDays + 1) /
          levelData?.totalTrainingDays) *
        100;

      await dispatch(
        updatePercentage({
          id: activeData?.levelId,
          updates: {
            trainingCompletionPercentage: updatedPercent,
            completedTrainingDays: levelData?.completedTrainingDays + 1
          }
        })
      );
      await dispatch(updateUserUncompletedCount());
      setIsAudioFinished(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (isAudioFinished && !activeData?.completedOn) onComplete();
  }, [isAudioFinished, activeData]);

  return (
    <Box display={"flex"} flexDirection="column" gap="10px">
      <Typography fontWeight={600} fontSize="28px">
        {activeData?.title}
      </Typography>

      <Typography fontSize={"18px"}>{activeData?.subTitle}</Typography>

      <AudioItem
        setIsAudioFinished={setIsAudioFinished}
        audio={activeData?.audioFile}
      />
      {sanitizedContent || sanitizedContent !== "undefined" ? (
        <div
          className="preview-html"
          style={{
            ".ql-align-center": {
              display: "flex",
              justifyContent: "center"
            },
            p: {
              color: `${styles.color} !important`
            }
          }}
          dangerouslySetInnerHTML={{
            __html: sanitizedContent ? sanitizedContent : <div />
          }}
        />
      ) : (
        <></>
      )}
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {activeData ? (
        <Button
          variant="contained"
          color="primary"
          onClick={onComplete}
          disabled={activeData?.completedOn}
          sx={{
            marginTop: 3.1,
            backgroundColor: "#38B65B",
            cursor: "pointer",
            color: "#fff",
            width: "100%",
            height: "42px",
            fontWeight: "bold",
            textTransform: "none",
            ":disabled": {
              backgroundColor: "#D7F0DE",
              color: "#9CDBAD",
              pointerEvents: "none"
            }
          }}
        >
          Complete
        </Button>
      ) : (
        <></>
      )}
      {(isMobile ? selectedLevel && activeLevelForMobile : selectedLevel) && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            zIndex: 100000
          }}
        >
          <IconButton
            variant="contained"
            sx={{
              display: "flex",
              backgroundColor: "#506DF0",
              color: "#FCFCFC",
              "&:hover": {
                backgroundColor: "#2fa273"
              }
            }}
            onClick={() => setOpenFAQModal((prev) => !prev)}
          >
            <Help />
          </IconButton>
          <FAQModal
            htmlValue={currentLevel?.faq}
            open={openFAQModal}
            onClose={() => setOpenFAQModal(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default RenderHtml;
