import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  completeTraining,
  getAllUserLevels,
  getAllUserTrainings
} from "../../api/trainingApi";

// Async thunk for fetching all meals with pagination
export const getCurrentUserLevels = createAsyncThunk(
  "training/getAllUserLevels",
  async (_, thunkAPI) => {
    try {
      const response = await getAllUserLevels();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserTrainings = createAsyncThunk(
  "training/getCurrentUserTrainings",
  async (levelId, thunkAPI) => {
    try {
      const response = await getAllUserTrainings(levelId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const completeTrainings = createAsyncThunk(
  "training/completeTrainings",
  async ({ date, levelId, trainingDayId }, thunkAPI) => {
    try {
      const response = await completeTraining(date, levelId, trainingDayId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  allTrainings: [],
  availableLevels: [], // holds the availableLevels data
  loading: false, // loading state for getCurrentUserLevels
  error: null // error state
};

const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {
    updateTrainingById: (state, action) => {
      const { id, updates } = action.payload;
      const index = state.allTrainings.findIndex(
        (item) => item.trainingId === id
      );
      if (index !== -1) {
        state.allTrainings[index] = {
          ...state.allTrainings[index],
          ...updates
        };
      }
    },
    updatePercentage: (state, action) => {
      const { id, updates } = action.payload;
      const index = state.availableLevels.findIndex(
        (item) => item.levelId === id
      );
      if (index !== -1) {
        state.availableLevels[index] = {
          ...state.availableLevels[index],
          ...updates
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserLevels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.availableLevels = action.payload.levels; // assuming response has meals data
      })
      .addCase(getCurrentUserLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUserTrainings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserTrainings.fulfilled, (state, action) => {
        state.loading = false;
        state.allTrainings = action.payload.trainings; // assuming response has meals data
      })
      .addCase(getCurrentUserTrainings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { updateTrainingById, updatePercentage } = trainingSlice.actions;

export default trainingSlice.reducer;
