// themeSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Load the initial theme mode from localStorage, or default to "light"
const initialTheme = localStorage.getItem("themeMode") || "dark";

const themeSlice = createSlice({
  name: "theme",
  initialState: { mode: initialTheme },
  reducers: {
    toggleTheme: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
      localStorage.setItem("themeMode", state.mode); // Save to localStorage
    },
    setTheme: (state, action) => {
      state.mode = action.payload;
      localStorage.setItem("themeMode", state.mode); // Save to localStorage
    }
  }
});

export const { toggleTheme, setTheme } = themeSlice.actions;
export default themeSlice.reducer;
