import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import CompleteVerification from "./components/Auth/CompleteVerification";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ChangePassword from "./components/Auth/ChangePassword";
import PrivateRoute from "./components/Auth/PrivateRoute";
import NotFound from "./components/NotFound";
import EditProfile from "./components/Modals/EditProfile";
import { Box, CssBaseline } from "@mui/material";
import Mod from "./components/Tabs/Mod";
import Profile from "./components/Tabs/Profile";
import AdminDashboard from "./pages/Dashboard";
import UpdatePassword from "./components/Modals/UpdatePassword";
import Meals from "./components/Tabs/Meals";
import Programs from "./components/Tabs/Programs";
import useThemeColor from "./utils/useTheme";
import Diary from "./components/Tabs/Diary";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const DashboardLayout = () => {
  const [activeLevelForMobile, setActiveLevelForMobile] = useState(false);
  const [activeDiaryLevel, setActiveDiaryLevel] = useState(false);
  const [open, setOpen] = React.useState(true);

  const styles = useThemeColor();
  return (
    <Box display="flex">
      <CssBaseline />
      <AdminDashboard
        open={open}
        setOpen={setOpen}
        activeLevelForMobile={activeLevelForMobile}
      />
      <Box
        component="main"
        id="dashboard-main"
        sx={{
          flexGrow: 1,
          color: styles.color,
          background: styles.background,
          padding: {
            xs: "0 0 115px 0",
            md: 0
          },
          height: "100dvh",
          overflow: "auto"
        }}
      >
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Mod />} />
          <Route
            path="/diary"
            element={
              <Diary
                activeLevelForMobile={activeDiaryLevel}
                setActiveLevelForMobile={setActiveDiaryLevel}
              />
            }
          />
          <Route
            path="/programs"
            element={
              <Programs
                open={open}
                activeLevelForMobile={activeLevelForMobile}
                setActiveLevelForMobile={setActiveLevelForMobile}
              />
            }
          />
          <Route path="/meals" element={<Meals />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/update-password" element={<UpdatePassword />} />
        </Routes>
      </Box>
    </Box>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/complete-registeration"
          element={<CompleteVerification />}
        />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ChangePassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
