import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../features/auth/authSlice";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert,
  InputAdornment,
  IconButton
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0, 3, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const [formErrors, setFormErrors] = useState({
    email: false
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEmailValidation = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    const { payload } = await dispatch(loginUser(formData));
    if (!payload.error) {
      if (!payload?.user?.isActive) {
        if (payload?.user?.userId)
          navigate("/complete-registeration?userId=" + payload.user.userId);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // localStorage.removeItem("expirationTime");
      } else {
        navigate("/");
      }
    } else {
      setSnackbarMessage(payload.error || "Login failed.");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const scrollableElement = document.documentElement; // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="auto"
      // height="100dvh"
      sx={{
        minHeight: "100dvh",
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </Box>

      <Box
        flexGrow={1}
        paddingBottom={4}
        paddingLeft={10}
        sx={{
          paddingRight: { xs: 5, md: 13, lg: 30 },
          paddingLeft: { xs: 5, md: 10 },
          paddingTop: { xs: 5, md: 17 },
          justifyContent: { xs: "space-between", md: "center" }
        }}
        display="flex"
        flexDirection="column"
      >
        <Box
          display={"flex"}
          sx={{
            justifyContent: { xs: "initial", md: "space-between" }
          }}
          flexDirection="column"
          height="100%"
        >
          <Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "block"
                },
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Typography fontSize="30px" fontWeight="bold" gutterBottom>
                Welcome Back
              </Typography>
              {/* <Typography color="#6B7280" fontSize="16px" gutterBottom>
                Sign in with email
              </Typography> */}
            </Box>
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={1000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity="error"
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Box component={"form"} mt={4} onSubmit={handleLogin}>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="email">
                  Email Address
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleEmailValidation}
                  required
                  error={formErrors.email}
                  helperText={formErrors.email && "Enter a valid email address"}
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseUpPassword}
                          sx={{ color: "#FCFCFC" }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  name="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  error={formErrors.password}
                  helperText={
                    formErrors.password &&
                    "Password must be 8 characters long: Contains 1 upper, 1 lower case, 1 symbol and 1 digit"
                  }
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Link
                  to="/forgot-password"
                  variant="outlined"
                  style={{
                    color: "#38B65B",
                    textDecoration: "none",
                    fontWeight: "bold"
                  }}
                >
                  Forgot Password
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || formErrors.email}
                sx={{
                  marginTop: 3.1,
                  backgroundColor: "#38B65B",
                  cursor: "pointer",
                  color: "#fff",
                  width: "100%",
                  height: "42px",
                  fontWeight: "bold",
                  textTransform: "none",
                  ":disabled": {
                    backgroundColor: "#D7F0DE",
                    color: "#9CDBAD",
                    pointerEvents: "none"
                  }
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Sign In"}
              </Button>
            </Box>
          </Box>
          <Box
            mt={3}
            display="flex"
            gap="20px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>New here?</Typography>
            <Link
              to="/register"
              variant="outlined"
              style={{
                color: "#38B65B",
                textDecoration: "none"
              }}
            >
              Sign Up
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
