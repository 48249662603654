import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  styled,
  Alert,
  useMediaQuery
} from "@mui/material";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { fetchUser } from "../../api/userApi";
import { completeProfile } from "../../features/auth/authSlice";
import { getUniqueSuffix } from "../../utils/getUniqueSuffix";
import { uploadFile } from "../../utils/uploadFile";
import useSignedUrl from "../../utils/useSignedUrl";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(5, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-15px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC",
    ":disabled": {
      backgroundColor: "#D7F0DE",
      color: "#FCFCFC",
      borderRadius: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));

const Register = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    displayName: "",
    email: "",
    password: "",
    profilePic: null,
    bio: "",
    url: "",
    userType: "",
    referenceName: "",
    isVerified: false
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    displayName: false,
    email: false,
    password: false,
    bio: false,
    url: false
  });
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validateUrl = (url) =>
    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(
      url
    );
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });

    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    } else if (name === "url") {
      setFormErrors({ ...formErrors, url: !validateUrl(value) });
    } else if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else if (name === "bio" && value.length > 50) {
      setFormErrors({ ...formErrors, bio: true });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file
      }));
    }
  };
  // users/getUserById/:id

  const handleRegister = async (e) => {
    // fullName: "",
    // displayName: "",
    // email: "",
    // password: "",
    // profilePic: null,
    // bio: "",
    // url: "",
    // userType: "",
    // referenceName: "",
    // isVerified: false
    delete formData.email;
    delete formData.password;
    delete formData.userType;
    delete formData.referenceName;

    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    try {
      const uniqueFileName = getUniqueSuffix(formData.profilePic?.name);
      const { profilePic, ...restFormData } = formData;
      const finalFormData = profilePic
        ? { ...restFormData, profilePic: uniqueFileName }
        : restFormData;

      const data = await dispatch(
        completeProfile({
          formData: { ...finalFormData, isVerified: false },
          userId
        })
      );
      if (formData.profilePic) {
        await uploadFile(
          formData.profilePic,
          uniqueFileName,
          process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
        );
      }

      if (data?.payload?.message) {
        setSnackbarMessage("Successfully user details updated!");
        setOpenSnackbar(true);
        setTimeout(() => navigate("/"), 2000);
      } else {
        setSnackbarMessage("Update user failed.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(
        "Update user failed:",
        error.response ? error.response.data : error.message
      );
      throw error.response ? error.response.data : error;
    }
  };
  const [exisitingImage, setExistingImage] = useState("");
  useEffect(() => {
    if (userId)
      fetchUser(userId).then((data) => {
        if (data && data.user) {
          setFormData({
            fullName: data.user.fullName,
            email: data.user.email,
            userType: data.user.userType,
            referenceName: data.user.referenceName
          });
          setExistingImage(data.user.profilePic);
        }
      });
  }, [userId]);
  const imgSrc = useSignedUrl(
    exisitingImage,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );
  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="hidden"
      height="100dvh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: { xs: "60px", md: "100px" },
            height: { xs: "60px", md: "100px" },
            objectFit: "cover"
          }}
        />
      </Box>

      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          paddingTop: { xs: 5, md: 8, lg: 10 },
          paddingRight: { xs: 5, md: 13, lg: 20 },
          paddingBottom: { xs: 5, md: 8, lg: 10 },
          paddingLeft: { xs: 5, md: 10 },
          height: { xs: "93%", md: "80%" },
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(255, 255, 255, 0.5)",
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "6px"
          }
        }}
      >
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "block"
            },
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              ...(matches && { display: "flex", justifyContent: "center" })
            }}
            fontSize="30px"
            fontWeight="bold"
            gutterBottom
          >
            Complete your profile
          </Typography>
          <Typography
            sx={{
              ...(matches && { display: "flex", justifyContent: "center" })
            }}
            color="#6B7280"
            fontSize="16px"
            gutterBottom
          >
            complete your remaining details here
          </Typography>
        </Box>
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarMessage.includes("Success") ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box component={"form"} mt={2} onSubmit={handleRegister}>
          <input
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-pic-upload"
          />
          <Box
            sx={{
              ...(matches && { justifyContent: "center" })
            }}
            display="flex"
            gap="50px"
            alignItems="center"
          >
            {formData.profilePic ? (
              <Avatar
                onClick={() =>
                  document.getElementById("profile-pic-upload").click()
                }
                alt="Profile Pic"
                src={URL.createObjectURL(formData.profilePic)}
                sx={{
                  width: 130,
                  height: 130,
                  marginTop: 2,
                  border: "1px solid #38B65B"
                }}
              />
            ) : imgSrc ? (
              <Avatar
                onClick={() =>
                  document.getElementById("profile-pic-upload").click()
                }
                alt="Profile Pic"
                src={imgSrc}
                sx={{
                  width: 130,
                  height: 130,
                  marginTop: 2,
                  border: "1px solid #38B65B"
                }}
              />
            ) : (
              <label htmlFor="profile-pic-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="default"
                  style={{
                    borderRadius: "48%",
                    backgroundColor: "#ccc",
                    width: 130,
                    height: 130,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 16,
                    cursor: "pointer"
                  }}
                >
                  <CameraIcon style={{ width: 24, height: 24 }} />
                </Button>
              </label>
            )}
          </Box>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="fullName">Name</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="fullName"
              placeholder="Enter your Full name"
              value={formData.fullName}
              onChange={handleInputChange}
              required
              error={formErrors.fullName}
              helperText={formErrors.fullName && "Name is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="displayName">
              Username (Displayed in app)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="displayName"
              placeholder="Enter your Username"
              value={formData.displayName}
              onChange={handleInputChange}
              required
              error={formErrors.displayName}
              helperText={formErrors.displayName && "Username is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="bio">
              Short Bio (50 character max)
            </StyledInputLabel>
            <StyledTextField
              sx={{
                "& .MuiInputBase-root": {
                  color: "#FCFCFC"
                }
              }}
              fullWidth
              variant="outlined"
              name="bio"
              placeholder="Enter your bio"
              value={formData.bio}
              multiline
              rows={4}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              onChange={handleInputChange}
              margin="normal"
              error={formErrors.bio}
              helperText={
                formErrors.bio && "Bio should be less than 50 characters"
              }
            />
            <Typography
              variant="caption"
              sx={{
                color: formData.bio?.length > 50 ? "red" : "#D1D5DB",
                position: "absolute",
                fontSize: "15px",
                right: 10,
                bottom: 10 // Adjust this value as necessary
              }}
            >
              {formData.bio?.length}/50
            </Typography>
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="email">Email Address</StyledInputLabel>
            <StyledTextField
              fullWidth
              disabled
              variant="outlined"
              name="email"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              required
              error={formErrors.email}
              helperText={formErrors.email && "Enter a valid email address"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
            <StyledTextField
              fullWidth
              disabled
              variant="outlined"
              name="password"
              type="password"
              placeholder="Enter Password"
              value={"Test1234"}
              onChange={handleInputChange}
              required
              error={formErrors.password}
              helperText={
                formErrors.password &&
                "Password must be 8 characters long: Contains 1 upper, 1 lower case, 1 symbol and 1 digit"
              }
              margin="normal"
            />
            <Typography fontSize={12}>
              (Change your password from profile page later)
            </Typography>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="url">
              URL (link to their website, social media, etc.)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="url"
              placeholder="Enter URL"
              value={formData.url}
              onChange={handleInputChange}
              error={formErrors.url}
              helperText={formErrors.url && "Enter a valid URL"}
              margin="normal"
            />
          </StyledTextFieldContainer>

          {formData.referenceName && (
            <>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="source-of-joining-label">
                  {" "}
                  Source of joining
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="source-of-joining-label"
                  value={formData.userType}
                  disabled
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="referenceName">
                  Name
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="referenceName"
                  value={formData.referenceName}
                  disabled
                  margin="normal"
                />
              </StyledTextFieldContainer>
            </>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            style={{
              marginTop: 22,
              backgroundColor: "#38B65B",
              color: "#FCFCFC",
              width: "100%",
              height: "42px",
              textTransform: "none"
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
