import {
  Box,
  Avatar,
  Paper,
  Typography,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeColor from "../../utils/useTheme";
import dayjs from "dayjs";
import { ReactComponent as Crown } from "../../assets/crown.svg";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { selectLeaderboardData } from "../../features/diary/diarySelectors";
import { getCurrentLevelLeaderboard } from "../../features/diary/diarySlice";
import useSignedUrl from "../../utils/useSignedUrl";
import getInitials from "../../utils/getInitials";

const ImageItem = ({ sx, img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      borderRadius="50%"
      sx={{
        ...sx,
        position: "relative"
      }}
    >
      <Box
        component="img"
        src={imgSrc}
        borderRadius="50%"
        alt="avatar"
        sx={{
          width: "100%",
          height: "100%"
        }}
      />
    </Box>
  );
};

function getLastSixMonths() {
  const months = [];
  for (let i = 0; i < 6; i++) {
    const date = dayjs().subtract(i, "month");
    const name = date.format("MMMM YYYY");
    const value = date.format("MM/YYYY");
    months.push({ name, value });
  }
  return months;
}

const getBackgroundGradient = (position, styles) => {
  let gradientColor;
  switch (position) {
    case 2:
      gradientColor = "#bbdefb";
      break;
    case 1:
      gradientColor = "#ffcc80";
      break;
    case 3:
      gradientColor = "#a5d6a7";
      break;
    default:
      return styles.background;
  }
  return `linear-gradient(0deg, ${gradientColor} -103%, ${styles.background} 40%)`;
};
const Leaderboard = ({ levelId }) => {
  const styles = useThemeColor();
  const lastSixMonths = getLastSixMonths();
  const [value, setValue] = useState(lastSixMonths?.[0]?.value);
  const theme = useTheme();
  const leaderBoardData = useSelector(selectLeaderboardData);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCurrentLevelLeaderboard({
        levelId,
        month: value?.split("/")[0],
        year: value?.split("/")[1]
      })
    );
  }, [dispatch, getCurrentLevelLeaderboard, value, levelId]);

  // Define top 3 users and other users based on array index
  const topUsers = leaderBoardData.slice(0, 3).map((user, index) => ({
    ...user,
    position: index + 1
  }));
  const otherUsers = leaderBoardData.slice(3).map((user, index) => ({
    ...user,
    position: index + 4 // Start from rank 4 for other users
  }));

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <FormControl
          sx={{
            width: { xs: "100%", md: "35%" },
            marginTop: "15px",
            svg: {
              fill: styles.color
            },
            ".MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: styles.lightGreyColor
              },
              "&:hover fieldset": {
                borderColor: styles.color
              },
              "&.Mui-focused fieldset": {
                borderColor: styles.color
              }
            }
          }}
        >
          <Select
            labelId="range-label"
            name="range"
            value={value}
            MenuProps={{
              MenuListProps: {
                sx: {
                  padding: 0,
                  ".Mui-selected": {
                    color: `${styles.background} !important`,
                    background: `${styles.color} !important`
                  }
                }
              }
            }}
            sx={{
              color: styles.color,
              marginTop: 0,
              paddingTop: 0
            }}
            onChange={(e) => setValue(e.target.value)}
          >
            {lastSixMonths.map((item) => (
              <MenuItem
                key={item.value}
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box maxWidth="md" mx="auto" p={4} mt={6}>
        {leaderBoardData?.length ? (
          <>
            {/* Top 3 Users */}
            <Box display="flex" justifyContent="center" mb={4}>
              {[topUsers?.[1], topUsers?.[0], topUsers?.[2]].map((user) => {
                return (
                  user && (
                    <Paper
                      key={user._id}
                      elevation={3}
                      sx={{
                        borderRadius:
                          user.position === 1
                            ? "15px 15px 0 0"
                            : user.position === 2
                            ? "15px 0 0 0"
                            : "0 15px 0 0",
                        padding: isMobile ? "45px 54px" : "25px 120px",
                        textAlign: "center",
                        background: getBackgroundGradient(
                          user.position,
                          styles
                        ),
                        mt: user.position === 1 ? 0 : 4,
                        position: "relative",
                        zIndex: user.position === 1 ? 2 : 1
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: -32,
                          left: "50%",
                          transform: "translateX(-50%)"
                        }}
                      >
                        {user?.profilePic ? (
                          <ImageItem
                            sx={{
                              position: "relative",
                              width: 70,
                              height: 70,
                              border: `3px solid ${
                                user.position === 1
                                  ? "#FFAA00"
                                  : user.position === 2
                                  ? "#368AD0"
                                  : "#38B65B"
                              }`,
                              boxShadow: 1
                            }}
                            img={user?.profilePic}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              width: 70,
                              height: 70,
                              border: `3px solid ${
                                user.position === 1
                                  ? "#FFAA00"
                                  : user.position === 2
                                  ? "#368AD0"
                                  : "#38B65B"
                              }`,
                              boxShadow: 1
                            }}
                          >
                            {user?.fullName
                              ? getInitials(user?.fullName)
                              : getInitials(user?.displayName)}
                          </Avatar>
                        )}
                        {user.position === 1 && (
                          <Crown
                            style={{
                              top: -25,
                              position: "absolute",
                              left: "50%",
                              transform: "translateX(-50%)",
                              color: "#ffa726",
                              width: 24,
                              height: 24
                            }}
                          />
                        )}
                        <Box
                          style={{
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%) rotate(45deg)",
                            color: styles.color,
                            borderRadius: "5px",
                            bottom: "-7px",
                            borderColor:
                              user.position === 1
                                ? "#FFAA00"
                                : user.position === 2
                                ? "#368AD0"
                                : "#38B65B",
                            background:
                              user.position === 1
                                ? "#FFAA00"
                                : user.position === 2
                                ? "#368AD0"
                                : "#38B65B",
                            width: 16,
                            height: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              transform: "rotate(-45deg)"
                            }}
                          >
                            {user.position}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        fontSize={isMobile ? "17px" : "20px"}
                        color={styles.color}
                        mt={4}
                      >
                        {user?.fullName}
                      </Typography>
                      <Typography
                        fontSize={isMobile ? "20px" : "25px"}
                        color={styles.color}
                        fontWeight="bold"
                      >
                        {user?.totalProgress}
                      </Typography>
                      <Typography fontSize={"10px"} color={styles.color}>
                        Days
                      </Typography>
                    </Paper>
                  )
                );
              })}
            </Box>

            {/* Other Users List */}
            <Box mt={4} spacing={2}>
              {otherUsers.map((user) => (
                <Paper
                  key={user?._id}
                  elevation={1}
                  sx={{
                    display: "flex",
                    background: styles.background,
                    color: styles.color,
                    alignItems: "center",
                    p: 2,
                    mb: 2
                  }}
                >
                  <Typography sx={{ width: 32 }}>#{user.position}</Typography>
                  {user?.profilePic ? (
                    <ImageItem
                      sx={{ width: 40, height: 40, mx: 2 }}
                      img={user?.profilePic}
                    />
                  ) : (
                    <Avatar sx={{ width: 40, height: 40, mx: 2 }}>
                      {user?.fullName
                        ? getInitials(user?.fullName)
                        : getInitials(user?.displayName)}
                    </Avatar>
                  )}
                  <Box flexGrow={1}>
                    <Typography variant="subtitle1">{user.fullName}</Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="h6" fontWeight="bold">
                      {user.totalProgress}
                    </Typography>
                    <Typography fontSize={"10px"} color={styles.color}>
                      Days
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
          </>
        ) : (
          <>No Leaderboard Data Found</>
        )}
      </Box>
    </Box>
  );
};

export default Leaderboard;
