import createApi from "../utils/axiosInstance";

export const getRecentMealsFromPast = async (
  currentDate,
  userId,
  type,
  searchTerm
) => {
  try {
    const api = createApi(true);

    const response = await api.get(
      `/user/get-recent-meal?date=${currentDate}&type=${type}&search=${searchTerm}${
        userId ? `&userId=${userId}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get recent meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
