import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Add, ChevronLeft } from "@mui/icons-material";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LockIcon } from "../../assets/lock1.svg";
import { ReactComponent as NoEntry } from "../../assets/no-entries.svg";
import dayjs from "dayjs";
import { ReactComponent as TrophyIcon } from "../../assets/trophy.svg";
import { ReactComponent as StatsIcon } from "../../assets/stats.svg";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import DiaryTracker from "../Extra/DiaryTracker";
import AddWeightForm from "../Modals/AddWeight";
import {
  copyYesterdayData,
  deleteIngredients,
  updateIngredients
} from "../../api/diaryApi";
import {
  completeDiaryDay,
  getCurrentUserDiaries,
  getCurrentUserDiaryLevels
} from "../../features/diary/diarySlice";
import {
  selectAllDiariesData,
  selectAllDiaryLevels
} from "../../features/diary/diarySelectors";
import PickerWithButtonField from "../Extra/DatePicker";
import {
  addMissingDates,
  generateDateArray
} from "../../utils/getAvailableDiaryDates";
import WeekView from "../Extra/WeekView";
import { options800gms, optionsProteins } from "../../utils/mockWeightData";
import LongMenu from "../Extra/ThreeDotMenu";
import useThemeColor from "../../utils/useTheme";
import {
  convertItemQuantityToGrams,
  getTotalWeight
} from "../../utils/weightConvert";
import StatsList from "../Extra/StatsList";
import ThreeDotMenu from "../Extra/ThreeDotMenuForMobile";
import Leaderboard from "../Extra/Leaderboard";
import { formatDate } from "../../utils/formatDate";

const Diary = ({ activeLevelForMobile, setActiveLevelForMobile }) => {
  const [selectedLevel, setSelectedLevel] = React.useState("");
  const [isShowingAllStats, setIsShowingAllStats] = useState(false);
  const [isShowingLeaderBoard, setIsShowingLeaderBoard] = useState(false);
  const dispatch = useDispatch();
  const [openWeightModal, setOpenWeightModal] = useState("");
  const [date, setDate] = useState(dayjs());
  const allLevels = useSelector(selectAllDiaryLevels);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const allDiaryData = useSelector(selectAllDiariesData);
  const userData = useSelector(selectCurrentUser);
  const [diaryData, setDiaryData] = useState([]);
  const [activeDay, setActiveDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [isAddingWeight, setIsAddingWeight] = useState("");
  const [editIngredientData, setEditIngredientData] = useState();
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);
  const styles = useThemeColor();
  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    setActiveLevelForMobile(true);
  };
  const currentLevel = useMemo(() => {
    return allLevels?.find((item) => item?.levelName === selectedLevel);
  }, [allLevels, selectedLevel]);

  useEffect(() => {
    if (userData?.activeDiaryLevel) {
      setSelectedLevel(userData?.activeDiaryLevel);
      setActiveLevelForMobile(true);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getCurrentUserDiaryLevels());
  }, [dispatch]);

  useEffect(() => {
    if (currentLevel?.levelId) {
      dispatch(getCurrentUserDiaries(currentLevel?.levelId));
    }
  }, [dispatch, currentLevel?.levelId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main");
    scrollableElement.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allDiaryData) {
      if (!isRefreshNeeded) {
        const previousDay = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        const previousDayData = allDiaryData?.find(
          (item) => item.currentDayDate === previousDay
        );
        if (
          (previousDayData?.entry800g?.length ||
            previousDayData?.protein?.length) &&
          !previousDayData?.completionDiaryDayDate
        ) {
          dispatch(
            completeDiaryDay({
              levelId: currentLevel?.levelId,
              diaryId: previousDayData?._id
            })
          );
          setTimeout(() => {
            dispatch(getCurrentUserDiaries(currentLevel?.levelId));
          }, 1000);
        }
      }
      const updatedData = addMissingDates(
        allDiaryData,
        dayjs(userData?.["createdAt"]).startOf("month"),
        currentLevel
      );
      setDiaryData(updatedData);
      !isRefreshNeeded && setActiveDay(dayjs().format("YYYY-MM-DD"));
    }
  }, [allDiaryData, userData]);

  const [startDay, setStartDay] = useState(1); // Start displaying from day 1

  const days = useMemo(() => {
    return currentLevel?.levelName
      ? generateDateArray(
          dayjs(userData?.["createdAt"]).startOf("month").format("YYYY-MM-DD")
        )
      : [];
  }, [currentLevel]);

  const visibleDaysCount = isMobile ? 5 : 8; // Number of days to show at a time
  const visibleDays = days.slice(startDay - 1, startDay - 1 + visibleDaysCount);

  // const [visibleDaysCount, setVisibleDaysCount] = useState(1);
  useEffect(() => {
    if (diaryData && !isRefreshNeeded) {
      const currentDay = dayjs().diff(
        dayjs(userData?.["createdAt"]).startOf("month").format("YYYY-MM-DD"),
        "day"
      );
      if (currentDay >= visibleDaysCount) {
        const pageIndex = Math.floor(currentDay / visibleDaysCount);
        // Calculate startDay for that page
        const calculatedStartDay = pageIndex * visibleDaysCount + 1;
        setStartDay(calculatedStartDay);
      } else {
        setStartDay(1); // Reset to 1 if currentDay is less than or equal to visibleDaysCount
      }
    }
  }, [diaryData]);

  const handleDayClick = (day) => {
    setActiveDay(day);
  };

  const currentActiveData = useMemo(() => {
    return diaryData?.find((item) => item.currentDayDate === activeDay);
  }, [diaryData, activeDay]);

  const allCompletedData = useMemo(() => {
    return diaryData
      ?.filter((item) => item.completionDiaryDayDate)
      ?.map((item) => item.currentDayDate);
  }, [diaryData]);

  const handleNext = () => {
    if (startDay + visibleDaysCount - 1 < days.length) {
      setStartDay((prev) => prev + visibleDaysCount);
    }
  };

  const handlePrevious = () => {
    if (startDay > 1) {
      setStartDay((prev) => prev - visibleDaysCount);
    }
  };

  return isAddingWeight ? (
    <AddWeightForm
      diaryId={currentActiveData?._id || ""}
      activeDay={activeDay}
      selectedLevel={currentLevel?.levelId}
      isAddingWeight={isAddingWeight}
      setIsAddingWeight={setIsAddingWeight}
      levelId={currentLevel?.levelId}
      editData={editIngredientData}
      setIsRefreshNeeded={setIsRefreshNeeded}
    />
  ) : (
    <Box
      pb={10}
      sx={{
        padding: isMobile ? "0" : "20px 0 40px 32px"
      }}
    >
      {((!activeLevelForMobile && isMobile) || !isMobile) && (
        <Box display="flex" flexDirection={"column"}>
          <Box
            sx={{
              ...(isMobile && {
                width: "100%",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                color: styles.color
              })
            }}
          >
            {isShowingAllStats ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton onClick={() => setIsShowingAllStats(false)}>
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Stats
                </Typography>
              </Box>
            ) : isShowingLeaderBoard ? (
              <Box display="flex" gap="20px" mb={2}>
                <IconButton onClick={() => setIsShowingLeaderBoard(false)}>
                  <ChevronLeft
                    sx={{ height: "35px", width: "35px", fill: styles.color }}
                  />
                </IconButton>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Monthly Leaderboard
                </Typography>
              </Box>
            ) : (
              <>
                <Typography
                  fontSize={isMobile ? "28px" : "30px"}
                  fontWeight={500}
                  color={styles.color}
                >
                  Welcome to Our Diary
                </Typography>
                <Typography
                  color="#6B7280"
                  fontSize={isMobile ? "16px" : "16px"}
                  fontWeight={isMobile ? 600 : 500}
                >
                  Make sure to keep track of your food
                </Typography>
              </>
            )}
          </Box>
          {isShowingLeaderBoard ? (
            <Box>
              <Leaderboard levelId={currentLevel?.levelId} />
            </Box>
          ) : (
            <Box mt={3} display="flex">
              <Box
                sx={{
                  width: !isMobile ? { md: "27%", lg: "27%" } : "100%",
                  borderRadius: "24px",
                  ...(!isMobile && {
                    height: "calc(100vh - 40px)"
                  }),
                  margin: 2,
                  background: styles.levelBackgrounds,
                  display: "flex",
                  flexDirection: "column",
                  padding: isMobile
                    ? "20px"
                    : { md: "24px 11px", lg: "24px 16px" },
                  overflowY: "auto"
                }}
                gap={4}
              >
                {allLevels?.map(({ title, levelName, levelActive }) => (
                  <Button
                    fullWidth
                    onClick={() => {
                      if (levelActive) handleLevelSelect(levelName);
                    }}
                    sx={{
                      padding: 6,
                      alignItems: "center",
                      border:
                        selectedLevel === levelName
                          ? "1px solid #9CDBAD"
                          : "1px solid #D1D5DB",
                      borderRadius: "16px",
                      backgroundColor:
                        selectedLevel === levelName
                          ? "#38B65B"
                          : // : isMobile
                            // ? styles.lightGreyColor
                            "transparent"
                    }}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      gap="16px"
                      justifyContent="space-between"
                      textTransform="none"
                      alignItems="center"
                    >
                      <Box textAlign={"start"}>
                        <Box>
                          <Typography
                            color={"#FCFCFC"}
                            fontSize="1.2rem"
                            fontWeight={600}
                          >
                            {title}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>{levelActive ? <></> : <LockIcon />}</Box>
                    </Box>
                  </Button>
                ))}
              </Box>
              {!isMobile && selectedLevel ? (
                isShowingAllStats ? (
                  <Box
                    display="flex"
                    sx={{
                      width: !isMobile ? { md: "60%", lg: "70%" } : "100%",
                      padding: "0 25px",
                      ...(!isMobile && { maxHeight: "calc(100vh - 40px)" })
                    }}
                    flexDirection="column"
                    gap={3}
                    ml={isMobile ? 0 : 2}
                  >
                    <StatsList level={currentLevel} />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    sx={{
                      width: !isMobile ? { md: "75%", lg: "85%" } : "100%",
                      padding: "0 25px",
                      ...(!isMobile && { maxHeight: "calc(100vh - 40px)" })
                    }}
                    flexDirection="column"
                    gap={3}
                    ml={isMobile ? 0 : 2}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box flexGrow={1} display="flex" justifyContent="center">
                        <PickerWithButtonField
                          maxDate={dayjs(userData?.["createdAt"])
                            .startOf("month")
                            .format("YYYY-MM-DD")}
                          allCompletedDates={allCompletedData}
                          date={date}
                          visibleDaysCount={visibleDaysCount}
                          setStartDay={setStartDay}
                          setDate={setDate}
                          activeDay={activeDay}
                          setActiveDay={setActiveDay}
                        />
                      </Box>
                      <Box display="flex" justifyContent="flex-end">
                        {/* <Button
                        onClick={() => setIsShowingAllStats(true)}
                        sx={{
                          fontSize: "small",
                          p: "2px 10px",
                          backgroundColor: "#38B65B",
                          cursor: "pointer",
                          color: "#fff",
                          textTransform: "none",
                          ":disabled": {
                            backgroundColor: "#D7F0DE",
                            color: "#9CDBAD",
                            pointerEvents: "none"
                          }
                        }}
                      >
                        All Stats
                      </Button> */}
                        <Box display="flex" gap="10px">
                          <IconButton
                            sx={{
                              padding: "0 12px",
                              borderRadius: "10px",
                              background: styles.lightDiaryTracker
                            }}
                            onClick={() => {
                              setIsShowingAllStats(true);
                            }}
                          >
                            <StatsIcon
                              style={{
                                width: 16,
                                height: 16
                              }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setIsShowingLeaderBoard(true);
                            }}
                            sx={{
                              padding: "0 12px",
                              borderRadius: "10px",
                              background: styles.lightDiaryTracker
                            }}
                          >
                            <TrophyIcon
                              style={{
                                width: 16,
                                height: 16
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <WeekView
                      allCompletedData={allCompletedData}
                      activeDay={activeDay}
                      setActiveDay={setActiveDay}
                      startDay={startDay}
                      visibleDays={visibleDays}
                      visibleDaysCount={visibleDaysCount}
                      days={days}
                      setStartDay={setStartDay}
                      diaryData={diaryData}
                      handleDayClick={handleDayClick}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                    />{" "}
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={"20px"}
                      sx={{
                        ...(!isMobile && {
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 120px)"
                        })
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          color={styles.color}
                          fontWeight={600}
                          fontSize={"24px"}
                        >
                          {formatDate(activeDay, false)}
                        </Typography>
                        <Button
                          disabled={
                            !diaryData?.find(
                              (item) =>
                                item.currentDayDate ===
                                dayjs(activeDay)
                                  ?.subtract(1, "day")
                                  ?.format("YYYY-MM-DD")
                            )?.entry800g?.length
                          }
                          onClick={async () => {
                            const previousDay = dayjs(activeDay)
                              ?.subtract(1, "day")
                              ?.format("YYYY-MM-DD");

                            const previousDayData = diaryData?.find(
                              (item) => item.currentDayDate === previousDay
                            );
                            await copyYesterdayData({
                              levelId: currentLevel?.levelId,
                              previousDiaryId: previousDayData?._id,
                              currentDiaryId: currentActiveData?._id,
                              currentDayDate: activeDay
                            });
                            setIsRefreshNeeded(true);
                            setTimeout(() => {
                              dispatch(
                                getCurrentUserDiaries(currentLevel?.levelId)
                              );
                            }, 2000);
                          }}
                          sx={{
                            fontSize: "small",
                            p: "0px 10px",
                            color: "#38B65B",
                            border: "1px solid #38B65B",
                            cursor: "pointer",
                            textTransform: "none",
                            ":disabled": {
                              backgroundColor: "#D7F0DE",
                              border: "none",
                              color: "#9CDBAD",
                              pointerEvents: "none"
                            }
                          }}
                        >
                          Copy yesterday
                        </Button>
                      </Box>
                      <Box
                        gap="40px"
                        display="flex"
                        borderRadius={"16px"}
                        sx={{ background: styles.diaryTracker }}
                        justifyContent="center"
                      >
                        <DiaryTracker
                          levelName={currentLevel?.levelName}
                          openWeightModal={openWeightModal}
                          setOpenWeightModal={setOpenWeightModal}
                          title={"#800gChallenge"}
                          levelId={currentLevel?.levelId}
                          diaryId={currentActiveData?._id || ""}
                          value={
                            getTotalWeight(currentActiveData?.entry800g) || 0
                          }
                          strokeColor={"#F6541C"}
                          maxValue={currentActiveData?.outOf800g}
                          setIsRefreshNeeded={setIsRefreshNeeded}
                          dayName={activeDay}
                          height={
                            currentLevel?.levelName === "level2" ? 210 : 250
                          }
                          width={
                            currentLevel?.levelName === "level2" ? 300 : 350
                          }
                        />
                        {currentLevel?.levelName === "level2" && (
                          <DiaryTracker
                            levelName={currentLevel?.levelName}
                            openWeightModal={openWeightModal}
                            setOpenWeightModal={setOpenWeightModal}
                            title={"Protein"}
                            levelId={currentLevel?.levelId}
                            diaryId={currentActiveData?._id || ""}
                            value={
                              getTotalWeight(currentActiveData?.protein) || 0
                            }
                            strokeColor={"#368AD0"}
                            maxValue={currentActiveData?.outOfProtein}
                            setIsRefreshNeeded={setIsRefreshNeeded}
                            dayName={activeDay}
                            height={
                              currentLevel?.levelName === "level2" ? 210 : 250
                            }
                            width={
                              currentLevel?.levelName === "level2" ? 300 : 350
                            }
                          />
                        )}
                      </Box>
                      <Box
                        width={"100%"}
                        gap="40px"
                        display="flex"
                        justifyContent="center"
                      >
                        <Button
                          onClick={() => {
                            setEditIngredientData();
                            setIsAddingWeight("entry800g");
                          }}
                          sx={{
                            textTransform: "none",
                            background: "#38B65B",
                            color: "#fcfcfc",
                            height: "40px",
                            fontWeight: 600,
                            padding: "12px 24px",
                            gap: "8px",
                            borderRadius: "4px"
                          }}
                        >
                          <Add /> Add 800g entry
                        </Button>
                      </Box>
                      {currentActiveData?.entry800g?.length ? (
                        <Box
                          // height={"200px"}
                          gap="10px"
                          borderRadius="16px"
                          p="20px"
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          sx={{ background: styles.lightDiaryTracker }}
                          justifyContent="center"
                        >
                          {currentActiveData?.entry800g?.map((item) => (
                            <Box
                              width={"100%"}
                              display="flex"
                              justifyContent="space-between"
                              key={item.key}
                            >
                              <Box>
                                <Typography
                                  fontSize={13}
                                  color={styles.color}
                                  textTransform={"capitalize"}
                                >
                                  {options800gms?.find(
                                    (option) => option.value === item.name
                                  )?.label ?? item.name}
                                </Typography>
                                <Typography
                                  fontSize={10}
                                  color={styles.disabledColor}
                                >
                                  {item.time}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems={"center"}>
                                <Typography fontSize={13} fontWeight={600}>
                                  {convertItemQuantityToGrams(item)?.quantity}g
                                </Typography>
                                <Box display="flex" gap={1} ml={1}>
                                  <IconButton
                                    sx={{
                                      fontSize: "16px",
                                      borderRadius: "4px",
                                      background: "#38B65B",
                                      svg: {
                                        path: {
                                          fill: "#FCFCFC"
                                        }
                                      },
                                      "&:hover": {
                                        background: "#38B62B"
                                      }
                                    }}
                                    onClick={() => {
                                      setEditIngredientData(item);
                                      setIsAddingWeight("entry800g");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                  <IconButton
                                    onClick={async () => {
                                      await deleteIngredients(
                                        currentLevel?.levelId,
                                        currentActiveData?._id,
                                        item?._id
                                      );
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries(
                                          currentLevel?.levelId
                                        )
                                      );
                                    }}
                                    sx={{
                                      fontSize: "16px",
                                      borderRadius: "4px",
                                      background: "#E94545",
                                      svg: {
                                        path: {
                                          fill: "#FCFCFC"
                                        }
                                      },
                                      "&:hover": {
                                        background: "#FF4000"
                                      }
                                    }}
                                  >
                                    {/* {currentTheme === "light" ? (
                                      <BlackDelete />
                                    ) : ( */}
                                    <Delete />
                                    {/* )} */}
                                  </IconButton>
                                </Box>
                                {/* <LongMenu
                                  onClickHandler={async (type) => {
                                    setEditIngredientData(item);
                                    if (type === "edit") {
                                      setIsAddingWeight("entry800g");
                                    } else {
                                      await deleteIngredients(
                                        currentLevel?.levelId,
                                        currentActiveData?._id,
                                        item?._id
                                      );
                                      setIsRefreshNeeded(true);
                                      dispatch(
                                        getCurrentUserDiaries(
                                          currentLevel?.levelId
                                        )
                                      );
                                    }
                                  }}
                                /> */}
                              </Box>
                            </Box>
                          ))}
                          <Button
                            onClick={async () => {
                              await updateIngredients({
                                diaryId: currentActiveData?._id,
                                levelId: currentLevel?.levelId,
                                entry800g: []
                              });
                              setIsRefreshNeeded(true);
                              dispatch(
                                getCurrentUserDiaries(currentLevel?.levelId)
                              );
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              p: "2px 40px",
                              color: styles.color,
                              border: `1px solid ${styles.color}`,
                              cursor: "pointer",
                              textTransform: "none"
                            }}
                          >
                            <IconButton
                              sx={{
                                color: styles.textColorDiary,
                                height: "17px",
                                width: "17px",
                                padding: 0,
                                ":disabled": {
                                  color: styles.textColorDiary
                                }
                              }}
                              disabled
                            >
                              <Add
                                color={styles.textColorDiary}
                                fontSize="12px"
                              />
                            </IconButton>
                            <Typography
                              color={styles.textColorDiary}
                              fontSize="13px"
                            >
                              Clear all
                            </Typography>
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          height={"200px"}
                          gap="10px"
                          borderRadius="16px"
                          p="20px"
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                          sx={{ background: styles.lightDiaryTracker }}
                          justifyContent="center"
                        >
                          <NoEntry />
                          <Typography color="#6B7280">No entry</Typography>
                        </Box>
                      )}
                      {currentLevel?.levelName === "level2" && (
                        <>
                          <Box
                            width={"100%"}
                            gap="40px"
                            display="flex"
                            justifyContent="center"
                          >
                            <Button
                              onClick={() => {
                                setEditIngredientData();
                                setIsAddingWeight("protein");
                              }}
                              sx={{
                                textTransform: "none",
                                background: "#38B65B",
                                color: "#fcfcfc",
                                height: "40px",
                                fontWeight: 600,
                                padding: "12px 24px",
                                gap: "8px",
                                borderRadius: "4px"
                              }}
                            >
                              <Add /> Add Protein entry
                            </Button>
                          </Box>
                          {currentActiveData?.protein?.length ? (
                            <Box
                              gap="10px"
                              borderRadius="16px"
                              p="20px"
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              sx={{ background: styles.lightDiaryTracker }}
                              justifyContent="center"
                            >
                              {currentActiveData?.protein?.map((item) => (
                                <Box
                                  width={"100%"}
                                  display="flex"
                                  justifyContent="space-between"
                                  key={item.key}
                                >
                                  <Box>
                                    <Typography
                                      fontSize={13}
                                      color={styles.color}
                                      textTransform={"capitalize"}
                                    >
                                      {optionsProteins?.find(
                                        (option) => option.value === item.name
                                      )?.label ?? item.name}
                                    </Typography>
                                    <Typography
                                      fontSize={10}
                                      color={styles.disabledColor}
                                    >
                                      {item.time}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" alignItems={"center"}>
                                    <Typography fontSize={13} fontWeight={600}>
                                      {
                                        convertItemQuantityToGrams(item)
                                          ?.quantity
                                      }
                                      g
                                    </Typography>
                                    <Box display="flex" ml={1} gap={1}>
                                      <IconButton
                                        sx={{
                                          fontSize: "16px",
                                          borderRadius: "4px",
                                          background: "#38B65B",
                                          svg: {
                                            path: {
                                              fill: "#FCFCFC"
                                            }
                                          },
                                          "&:hover": {
                                            background: "#38B62B"
                                          }
                                        }}
                                        onClick={() => {
                                          setEditIngredientData(item);
                                          setIsAddingWeight("protein");
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                      <IconButton
                                        onClick={async () => {
                                          await deleteIngredients(
                                            currentLevel?.levelId,
                                            currentActiveData?._id,
                                            item?._id
                                          );
                                          setIsRefreshNeeded(true);
                                          dispatch(
                                            getCurrentUserDiaries(
                                              currentLevel?.levelId
                                            )
                                          );
                                        }}
                                        sx={{
                                          fontSize: "16px",
                                          borderRadius: "4px",
                                          background: "#E94545",
                                          svg: {
                                            path: {
                                              fill: "#FCFCFC"
                                            }
                                          },
                                          "&:hover": {
                                            background: "#FF4000"
                                          }
                                        }}
                                      >
                                        {/* {currentTheme === "light" ? (
                                          <BlackDelete />
                                        ) : ( */}
                                        <Delete />
                                        {/* )} */}
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              ))}
                              <Button
                                onClick={async () => {
                                  await updateIngredients({
                                    diaryId: currentActiveData?._id,
                                    levelId: currentLevel?.levelId,
                                    protein: []
                                  });
                                  setIsRefreshNeeded(true);
                                  dispatch(
                                    getCurrentUserDiaries(currentLevel?.levelId)
                                  );
                                }}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  p: "2px 40px",
                                  color: styles.color,
                                  border: `1px solid ${styles.color}`,
                                  cursor: "pointer",
                                  textTransform: "none"
                                }}
                              >
                                <IconButton
                                  sx={{
                                    color: styles.textColorDiary,
                                    height: "17px",
                                    width: "17px",
                                    padding: 0,
                                    ":disabled": {
                                      color: styles.textColorDiary
                                    }
                                  }}
                                  disabled
                                >
                                  <Add
                                    color={styles.textColorDiary}
                                    fontSize="12px"
                                  />
                                </IconButton>
                                <Typography
                                  color={styles.textColorDiary}
                                  fontSize="13px"
                                >
                                  Clear all
                                </Typography>
                              </Button>
                            </Box>
                          ) : (
                            <Box
                              height={"200px"}
                              gap="10px"
                              borderRadius="16px"
                              p="20px"
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              sx={{ background: styles.lightDiaryTracker }}
                              justifyContent="center"
                            >
                              <NoEntry />
                              <Typography color="#6B7280">No entry</Typography>
                            </Box>
                          )}
                        </>
                      )}
                      <Box display="flex" justifyContent="center">
                        <Button
                          onClick={() => {
                            dispatch(
                              completeDiaryDay({
                                levelId: currentLevel?.levelId,
                                diaryId: currentActiveData?._id
                              })
                            );
                            setIsRefreshNeeded(true);
                            setTimeout(() => {
                              dispatch(
                                getCurrentUserDiaries(currentLevel?.levelId)
                              );
                            }, 2000);
                          }}
                          disabled={
                            currentActiveData?.completionDiaryDayDate ||
                            !currentActiveData?.entry800g?.length
                          }
                          sx={{
                            fontSize: "small",
                            p: "10px 20px",
                            color: "#38B65B",
                            border: "1px solid #38B65B",
                            cursor: "pointer",
                            textTransform: "none",
                            ":disabled": {
                              backgroundColor: "#D7F0DE",
                              border: "none",
                              color: "#9CDBAD",
                              pointerEvents: "none"
                            }
                          }}
                        >
                          Complete the day
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )
              ) : (
                <></>
              )}
            </Box>
          )}
        </Box>
      )}
      {activeLevelForMobile && isMobile && (
        <Box
          display="flex"
          sx={{
            width: "100%",
            padding: "10px 25px"
          }}
          flexDirection="column"
          gap={3}
        >
          {isShowingAllStats ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton
                onClick={() => {
                  setIsShowingAllStats(false);
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                  width={30}
                  height={30}
                  color={styles.color}
                />
              </IconButton>

              <Typography fontWeight={600} fontSize={"18px"}>
                Stats
              </Typography>
              <div />
            </Box>
          ) : isShowingLeaderBoard ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
              mr={isMobile ? 2 : 0}
            >
              <IconButton onClick={() => setIsShowingLeaderBoard(false)}>
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                />
              </IconButton>
              <Typography fontWeight={600} fontSize={"18px"}>
                Monthly Leaderboard
              </Typography>
              <div />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={"16%"}
            >
              <IconButton
                onClick={() => {
                  setActiveLevelForMobile(false);
                }}
              >
                <ChevronLeft
                  sx={{
                    width: 30,
                    height: 30,
                    color: styles.color
                  }}
                  width={30}
                  height={30}
                  color={styles.color}
                />
              </IconButton>

              <PickerWithButtonField
                maxDate={dayjs(userData?.["createdAt"])
                  .startOf("month")
                  .format("YYYY-MM-DD")}
                allCompletedDates={allCompletedData}
                date={date}
                visibleDaysCount={visibleDaysCount}
                setStartDay={setStartDay}
                setDate={setDate}
                setActiveDay={setActiveDay}
              />
              <ThreeDotMenu
                onClickHandler={async (type) => {
                  if (type === "stats") setIsShowingAllStats(true);
                  else setIsShowingLeaderBoard(true);
                }}
              />
            </Box>
          )}
          {isShowingAllStats ? (
            <StatsList level={currentLevel} />
          ) : isShowingLeaderBoard ? (
            <Leaderboard levelId={currentLevel?.levelId} />
          ) : (
            <>
              <WeekView
                allCompletedData={allCompletedData}
                activeDay={activeDay}
                setActiveDay={setActiveDay}
                startDay={startDay}
                visibleDays={visibleDays}
                visibleDaysCount={visibleDaysCount}
                days={days}
                setStartDay={setStartDay}
                diaryData={diaryData}
                handleDayClick={handleDayClick}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />{" "}
              <Box
                display="flex"
                flexDirection="column"
                gap={"20px"}
                sx={{
                  ...(!isMobile && {
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 120px)"
                  })
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    color={styles.color}
                    fontWeight={600}
                    fontSize={"24px"}
                  >
                    {dayjs(activeDay)?.isSame(dayjs(), "day")
                      ? "Today"
                      : dayjs(activeDay)?.format("MMMM D")}
                  </Typography>
                  <Button
                    disabled={
                      !diaryData?.find(
                        (item) =>
                          item.currentDayDate ===
                          dayjs(activeDay)
                            ?.subtract(1, "day")
                            ?.format("YYYY-MM-DD")
                      )?.entry800g?.length
                    }
                    onClick={async () => {
                      const previousDay = dayjs(activeDay)
                        ?.subtract(1, "day")
                        ?.format("YYYY-MM-DD");

                      const previousDayData = diaryData?.find(
                        (item) => item.currentDayDate === previousDay
                      );
                      await copyYesterdayData({
                        levelId: currentLevel?.levelId,
                        previousDiaryId: previousDayData?._id,
                        currentDiaryId: currentActiveData?._id,
                        currentDayDate: activeDay
                      });
                      setIsRefreshNeeded(true);
                      setTimeout(() => {
                        dispatch(getCurrentUserDiaries(currentLevel?.levelId));
                      }, 2000);
                    }}
                    sx={{
                      fontSize: "small",
                      p: "0px 10px",
                      color: "#38B65B",
                      border: "1px solid #38B65B",
                      cursor: "pointer",
                      textTransform: "none",
                      ":disabled": {
                        backgroundColor: "#D7F0DE",
                        border: "none",
                        color: "#9CDBAD",
                        pointerEvents: "none"
                      }
                    }}
                  >
                    Copy yesterday
                  </Button>
                </Box>
                <Box
                  display="flex"
                  borderRadius={"16px"}
                  sx={{ background: styles.diaryTracker }}
                  justifyContent="center"
                >
                  <DiaryTracker
                    levelName={currentLevel?.levelName}
                    openWeightModal={openWeightModal}
                    setOpenWeightModal={setOpenWeightModal}
                    title={"#800gChallenge"}
                    levelId={currentLevel?.levelId}
                    diaryId={currentActiveData?._id || ""}
                    value={getTotalWeight(currentActiveData?.entry800g) || 0}
                    strokeColor={"#F6541C"}
                    maxValue={currentActiveData?.outOf800g}
                    setIsRefreshNeeded={setIsRefreshNeeded}
                    dayName={activeDay}
                    height={currentLevel?.levelName === "level2" ? 130 : 250}
                    width={currentLevel?.levelName === "level2" ? 190 : 350}
                  />
                  {currentLevel?.levelName === "level2" && (
                    <DiaryTracker
                      levelName={currentLevel?.levelName}
                      openWeightModal={openWeightModal}
                      setOpenWeightModal={setOpenWeightModal}
                      title={"Protein"}
                      levelId={currentLevel?.levelId}
                      diaryId={currentActiveData?._id || ""}
                      value={getTotalWeight(currentActiveData?.protein) || 0}
                      strokeColor={"#368AD0"}
                      maxValue={currentActiveData?.outOfProtein}
                      setIsRefreshNeeded={setIsRefreshNeeded}
                      dayName={activeDay}
                      height={currentLevel?.levelName === "level2" ? 130 : 250}
                      width={currentLevel?.levelName === "level2" ? 190 : 350}
                    />
                  )}
                </Box>
                <Box
                  width={"100%"}
                  gap="40px"
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    onClick={() => {
                      setEditIngredientData();
                      setIsAddingWeight("entry800g");
                    }}
                    sx={{
                      textTransform: "none",
                      background: "#38B65B",
                      color: "#fcfcfc",
                      height: "40px",
                      fontWeight: 600,
                      padding: "12px 24px",
                      gap: "8px",
                      borderRadius: "4px"
                    }}
                  >
                    <Add /> Add 800g entry
                  </Button>
                </Box>
                {currentActiveData?.entry800g?.length ? (
                  <Box
                    // height={"200px"}
                    gap="10px"
                    borderRadius="16px"
                    p="20px"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    sx={{ background: styles.lightDiaryTracker }}
                    justifyContent="center"
                  >
                    {currentActiveData?.entry800g?.map((item) => (
                      <Box
                        width={"100%"}
                        display="flex"
                        justifyContent="space-between"
                        key={item.key}
                      >
                        <Box>
                          <Typography
                            fontSize={13}
                            color={styles.color}
                            textTransform={"capitalize"}
                          >
                            {options800gms?.find(
                              (option) => option.value === item.name
                            )?.label ?? item.name}
                          </Typography>
                          <Typography
                            fontSize={10}
                            color={styles.disabledColor}
                          >
                            {item.time}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems={"center"}>
                          <Typography fontSize={13} fontWeight={600}>
                            {convertItemQuantityToGrams(item)?.quantity}g
                          </Typography>
                          <LongMenu
                            onClickHandler={async (type) => {
                              setEditIngredientData(item);
                              if (type === "edit") {
                                setIsAddingWeight("entry800g");
                              } else {
                                await deleteIngredients(
                                  currentLevel?.levelId,
                                  currentActiveData?._id,
                                  item?._id
                                );
                                setIsRefreshNeeded(true);
                                dispatch(
                                  getCurrentUserDiaries(currentLevel?.levelId)
                                );
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                    <Button
                      onClick={async () => {
                        await updateIngredients({
                          diaryId: currentActiveData?._id,
                          levelId: currentLevel?.levelId,
                          entry800g: []
                        });
                        setIsRefreshNeeded(true);
                        dispatch(getCurrentUserDiaries(currentLevel?.levelId));
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        p: "2px 40px",
                        color: styles.color,
                        border: `1px solid ${styles.color}`,
                        cursor: "pointer",
                        textTransform: "none"
                      }}
                    >
                      <IconButton
                        sx={{
                          color: styles.textColorDiary,
                          height: "17px",
                          width: "17px",
                          padding: 0,
                          ":disabled": {
                            color: styles.textColorDiary
                          }
                        }}
                        disabled
                      >
                        <Add color={styles.textColorDiary} fontSize="12px" />
                      </IconButton>
                      <Typography color={styles.textColorDiary} fontSize="13px">
                        Clear all
                      </Typography>
                    </Button>
                  </Box>
                ) : (
                  <Box
                    height={"200px"}
                    gap="10px"
                    borderRadius="16px"
                    p="20px"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    sx={{ background: styles.lightDiaryTracker }}
                    justifyContent="center"
                  >
                    <NoEntry />
                    <Typography color="#6B7280">No entry</Typography>
                  </Box>
                )}
                {currentLevel?.levelName === "level2" && (
                  <>
                    <Box
                      width={"100%"}
                      gap="40px"
                      display="flex"
                      justifyContent="center"
                    >
                      <Button
                        onClick={() => {
                          setEditIngredientData();
                          setIsAddingWeight("protein");
                        }}
                        sx={{
                          textTransform: "none",
                          background: "#38B65B",
                          color: "#fcfcfc",
                          height: "40px",
                          fontWeight: 600,
                          padding: "12px 24px",
                          gap: "8px",
                          borderRadius: "4px"
                        }}
                      >
                        <Add /> Add Protein entry
                      </Button>
                    </Box>
                    {currentActiveData?.protein?.length ? (
                      <Box
                        gap="10px"
                        borderRadius="16px"
                        p="20px"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ background: styles.lightDiaryTracker }}
                        justifyContent="center"
                      >
                        {currentActiveData?.protein?.map((item) => (
                          <Box
                            width={"100%"}
                            display="flex"
                            justifyContent="space-between"
                            key={item.key}
                          >
                            <Box>
                              <Typography
                                fontSize={13}
                                color={styles.color}
                                textTransform={"capitalize"}
                              >
                                {optionsProteins?.find(
                                  (option) => option.value === item.name
                                )?.label ?? item.name}
                              </Typography>
                              <Typography
                                fontSize={10}
                                color={styles.disabledColor}
                              >
                                {item.time}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems={"center"}>
                              <Typography fontSize={13} fontWeight={600}>
                                {convertItemQuantityToGrams(item)?.quantity}g
                              </Typography>
                              <LongMenu
                                onClickHandler={async (type) => {
                                  setEditIngredientData(item);
                                  if (type === "edit") {
                                    setIsAddingWeight("protein");
                                  } else {
                                    await deleteIngredients(
                                      currentLevel?.levelId,
                                      currentActiveData?._id,
                                      item?._id
                                    );
                                    setIsRefreshNeeded(true);
                                    dispatch(
                                      getCurrentUserDiaries(
                                        currentLevel?.levelId
                                      )
                                    );
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                        <Button
                          onClick={async () => {
                            await updateIngredients({
                              diaryId: currentActiveData?._id,
                              levelId: currentLevel?.levelId,
                              protein: []
                            });
                            setIsRefreshNeeded(true);
                            dispatch(
                              getCurrentUserDiaries(currentLevel?.levelId)
                            );
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            p: "2px 40px",
                            color: styles.color,
                            border: `1px solid ${styles.color}`,
                            cursor: "pointer",
                            textTransform: "none"
                          }}
                        >
                          <IconButton
                            sx={{
                              color: styles.textColorDiary,
                              height: "17px",
                              width: "17px",
                              padding: 0,
                              ":disabled": {
                                color: styles.textColorDiary
                              }
                            }}
                            disabled
                          >
                            <Add
                              color={styles.textColorDiary}
                              fontSize="12px"
                            />
                          </IconButton>
                          <Typography
                            color={styles.textColorDiary}
                            fontSize="13px"
                          >
                            Clear all
                          </Typography>
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        height={"200px"}
                        gap="10px"
                        borderRadius="16px"
                        p="20px"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ background: styles.lightDiaryTracker }}
                        justifyContent="center"
                      >
                        <NoEntry />
                        <Typography color="#6B7280">No entry</Typography>
                      </Box>
                    )}
                  </>
                )}
                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={() => {
                      dispatch(
                        completeDiaryDay({
                          levelId: currentLevel?.levelId,
                          diaryId: currentActiveData?._id
                        })
                      );
                      dispatch(getCurrentUserDiaries(currentLevel?.levelId));
                      setIsRefreshNeeded(true);
                    }}
                    disabled={
                      currentActiveData?.completionDiaryDayDate ||
                      !currentActiveData?.entry800g?.length
                    }
                    sx={{
                      fontSize: "small",
                      p: "10px 20px",
                      color: "#38B65B",
                      border: "1px solid #38B65B",
                      cursor: "pointer",
                      textTransform: "none",
                      ":disabled": {
                        backgroundColor: "#D7F0DE",
                        border: "none",
                        color: "#9CDBAD",
                        pointerEvents: "none"
                      }
                    }}
                  >
                    Complete the day
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Diary;
