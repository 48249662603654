import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import DaySelector from "../Extra/DayChanger";
import { ReactComponent as LockIcon } from "../../assets/lock1.svg";
import {
  getCurrentUserLevels,
  getCurrentUserTrainings
} from "../../features/training/trainingSlice";
import {
  selectAllTrainingsData,
  selectLevelData
} from "../../features/training/trainingSelectors";
import CircularProgressStatic from "../Extra/ProgressTracker";
import RenderHtml from "../Extra/RenderHtml/RenderHtml";
import dayjs from "dayjs";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import useThemeColor from "../../utils/useTheme";

const Programs = ({ open, activeLevelForMobile, setActiveLevelForMobile }) => {
  const [selectedLevel, setSelectedLevel] = React.useState("");
  const dispatch = useDispatch();
  const styles = useThemeColor();
  const allLevels = useSelector(selectLevelData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const trainingData = useSelector(selectAllTrainingsData);
  const [activeDay, setActiveDay] = useState(0);
  const userData = useSelector(selectCurrentUser);
  const [isAudioFinished, setIsAudioFinished] = useState(false);

  useEffect(() => {
    if (userData.activeLevel && userData.pendingTrainingDays) {
      setSelectedLevel(userData.activeLevel);
      setActiveLevelForMobile(true);
    }
  }, [userData]);
  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    setActiveLevelForMobile(true);
  };
  const currentLevel = useMemo(() => {
    return allLevels?.find((item) => item?.levelName === selectedLevel);
  }, [allLevels, selectedLevel]);

  useEffect(() => {
    dispatch(getCurrentUserLevels());
  }, [dispatch]);

  useEffect(() => {
    if (currentLevel?.levelId)
      dispatch(getCurrentUserTrainings(currentLevel?.levelId));
  }, [dispatch, currentLevel?.levelId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main");
    scrollableElement.scrollTo(0, 0);
  }, []);

  const calculateActiveDay = useCallback(() => {
    if (!trainingData || trainingData.length === 0) return 0;

    let activeDay = 0;

    for (let i = 0; i < trainingData?.length; i++) {
      const task = trainingData[i];
      const day = +task.day.split(" ")[1];
      if (!task.completedOn && day) {
        activeDay += day;
        break;
      }
    }

    // Limit activeDay to the maximum day in enabledDays
    const maxEnabledDay = Math.max(
      ...Array.from(
        {
          length:
            dayjs()
              .startOf("day")
              .diff(
                dayjs(
                  dayjs(userData?.level1_start_date).utc().format("YYYY-MM-DD")
                ).startOf("day"),
                "day"
              ) + 1
        },
        (_, i) => i + 1
      )
    );
    return Math.min(activeDay, maxEnabledDay);
  }, [trainingData, userData]);

  const [startDay, setStartDay] = useState(1); // Start displaying from day 1

  const days = Array.from({ length: trainingData?.length }, (_, i) => i + 1); // Days 1 to 30
  const visibleDaysCount = isMobile ? 5 : 8; // Number of days to show at a time
  const visibleDays = days.slice(startDay - 1, startDay - 1 + visibleDaysCount);

  useEffect(() => {
    if (trainingData) {
      const currentDay = calculateActiveDay(trainingData);
      setActiveDay(currentDay);
      if (currentDay > visibleDaysCount) {
        const pageIndex = Math.floor((currentDay - 1) / visibleDaysCount);
        // Calculate startDay for that page
        const calculatedStartDay = pageIndex * visibleDaysCount + 1;
        setStartDay(calculatedStartDay);
      } else {
        setStartDay(1); // Reset to 1 if currentDay is less than or equal to visibleDaysCount
      }
    }
  }, [trainingData]);

  const handleDayClick = (day) => {
    setActiveDay(day);
    setIsAudioFinished(false);
  };

  const currentActiveData = useMemo(() => {
    return trainingData?.find((item) => item.day === `Day ${activeDay}`);
  }, [trainingData, activeDay]);

  const allCompletedData = useMemo(() => {
    return trainingData
      ?.filter((item) => item.completedOn)
      ?.map((item) => +item.day.split(" ")?.[1]);
  }, [trainingData]);

  const handleNext = () => {
    if (startDay + visibleDaysCount - 1 < days.length) {
      setStartDay((prev) => prev + visibleDaysCount);
      setIsAudioFinished(false);
    }
  };

  const handlePrevious = () => {
    if (startDay > 1) {
      setStartDay((prev) => prev - visibleDaysCount);
      setIsAudioFinished(false);
    }
  };

  return (
    <Box
      pb={10}
      sx={{
        padding: isMobile ? "0" : "20px 0 40px 32px"
      }}
    >
      {((!activeLevelForMobile && isMobile) || !isMobile) && (
        <Box display="flex" flexDirection={"column"}>
          <Box
            sx={{
              ...(isMobile && {
                width: "100%",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                color: "#FCFCFC"
              })
            }}
          >
            <Typography
              fontSize={isMobile ? "28px" : "30px"}
              fontWeight={500}
              color={styles.color}
            >
              Welcome to Our Programs
            </Typography>
            <Typography
              color="#6B7280"
              fontSize={isMobile ? "16px" : "16px"}
              fontWeight={isMobile ? 600 : 500}
            >
              Learn how dieting can improve lifestyle
            </Typography>
          </Box>
          <Box mt={3} display="flex">
            <Box
              sx={{
                width: !isMobile ? { md: open ? "27%" : "24%" } : "100%",
                borderRadius: "24px",
                ...(!isMobile && {
                  height: "calc(100vh - 40px)"
                }),
                background: styles.levelBackgrounds,
                margin: 2,
                display: "flex",
                flexDirection: "column",
                padding: isMobile
                  ? "20px"
                  : { md: "24px 11px", lg: "24px 16px" },
                overflowY: "auto"
              }}
              gap={4}
            >
              {allLevels?.map(
                ({
                  title,
                  trainingCompletionPercentage,
                  levelName,
                  levelActive,
                  description
                }) => (
                  <Button
                    fullWidth
                    onClick={() => {
                      if (levelActive) handleLevelSelect(levelName);
                    }}
                    sx={{
                      padding: 3,
                      alignItems: "center",
                      border:
                        selectedLevel === levelName
                          ? "1px solid #9CDBAD"
                          : "1px solid #D1D5DB",
                      borderRadius: "16px",
                      backgroundColor:
                        selectedLevel === levelName
                          ? "#38B65B"
                          : // : isMobile
                            // ? styles.lightGreyColor
                            "transparent"
                    }}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      gap="16px"
                      justifyContent="space-between"
                      textTransform="none"
                      alignItems="center"
                    >
                      <Box textAlign={"start"}>
                        {levelName === "level1" && (
                          <Box
                            textAlign="start"
                            sx={{
                              background: "#D61A1A",
                              width: "105px",
                              padding: "2px 6px"
                            }}
                          >
                            <Typography
                              fontSize="9px"
                              textTransform="capitalize"
                              fontWeight="bold"
                              color="#FCFCFC"
                            >
                              FIRST 5 DAYS FREE
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          <Typography
                            color={"#FCFCFC"}
                            fontSize="1rem"
                            fontWeight={600}
                          >
                            {title}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            width={isMobile ? "80%" : "100%"}
                            color={"#FCFCFC"}
                            fontSize="12px"
                          >
                            {description}
                          </Typography>
                        </Box>
                        <Button
                          sx={{
                            mt: 1,
                            height: "23px",
                            textTransform: "none",
                            background:
                              selectedLevel === levelName
                                ? "#FCFCFC"
                                : "#D1D5DB",
                            color:
                              selectedLevel === levelName ? "#34C38F" : "black",
                            gap: "10px"
                          }}
                        >
                          <Typography fontSize="12px" fontWeight={500}>
                            Buy Now
                          </Typography>
                          <IconButton
                            sx={{
                              background:
                                selectedLevel === levelName
                                  ? "#34C38F"
                                  : "#14142B",
                              borderRadius: "50%",
                              padding: "1px"
                            }}
                          >
                            <ChevronRight
                              sx={{
                                height: 12,
                                width: 12,
                                color: "#FCFCFC"
                              }}
                            />
                          </IconButton>
                        </Button>
                      </Box>
                      <Box>
                        {levelActive ? (
                          <CircularProgressStatic
                            levelName={levelName}
                            selected={selectedLevel === levelName}
                            percentage={trainingCompletionPercentage}
                          />
                        ) : (
                          <LockIcon />
                        )}
                      </Box>
                    </Box>
                  </Button>
                )
              )}
            </Box>
            {!isMobile && selectedLevel && (
              <Box
                display="flex"
                sx={{
                  width: !isMobile ? { md: open ? "68%" : "73%" } : "100%",
                  padding: "0 25px",
                  ...(!isMobile && { maxHeight: "calc(100vh - 40px)" })
                }}
                flexDirection="column"
                gap={3}
                ml={isMobile ? 0 : 2}
              >
                <DaySelector
                  enabledDays={Array.from(
                    {
                      length:
                        dayjs()
                          .startOf("day")
                          .diff(
                            dayjs(
                              dayjs(userData?.level1_start_date)
                                .utc()
                                .format("YYYY-MM-DD")
                            ).startOf("day"),
                            "day"
                          ) + 1
                    },
                    (_, i) => i + 1
                  )}
                  allCompletedData={allCompletedData}
                  activeDay={activeDay}
                  setActiveDay={setActiveDay}
                  startDay={startDay}
                  visibleDays={visibleDays}
                  visibleDaysCount={visibleDaysCount}
                  days={days}
                  setStartDay={setStartDay}
                  trainingData={trainingData}
                  handleDayClick={handleDayClick}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
                <Box
                  sx={{
                    ...(!isMobile && {
                      overflowY: "auto",
                      maxHeight: "calc(100vh - 120px)"
                    })
                  }}
                >
                  <RenderHtml
                    isMobile={isMobile}
                    selectedLevel={selectedLevel}
                    currentLevel={currentLevel}
                    activeLevelForMobile={activeLevelForMobile}
                    setIsAudioFinished={setIsAudioFinished}
                    isAudioFinished={isAudioFinished}
                    styles={styles}
                    activeDay={activeDay}
                    activeData={currentActiveData}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {activeLevelForMobile && isMobile && (
        <Box
          display="flex"
          sx={{
            width: "100%",
            padding: "10px 25px"
          }}
          flexDirection="column"
          gap={3}
        >
          <Box display="flex" alignItems="center" gap={"16%"}>
            <IconButton onClick={() => setActiveLevelForMobile(false)}>
              <ChevronLeft
                sx={{
                  width: 30,
                  height: 30,
                  color: styles.color
                }}
                width={30}
                height={30}
              />
            </IconButton>
            <Typography fontWeight={500} fontSize={"24px"}>
              {" "}
              {currentLevel?.title}{" "}
            </Typography>
          </Box>
          <DaySelector
            enabledDays={Array.from(
              {
                length:
                  dayjs()
                    .startOf("day")
                    .diff(
                      dayjs(
                        dayjs(userData?.level1_start_date)
                          .utc()
                          .format("YYYY-MM-DD")
                      ).startOf("day"),
                      "day"
                    ) + 1
              },
              (_, i) => i + 1
            )}
            allCompletedData={allCompletedData}
            activeDay={activeDay}
            setActiveDay={setActiveDay}
            startDay={startDay}
            visibleDays={visibleDays}
            visibleDaysCount={visibleDaysCount}
            days={days}
            setStartDay={setStartDay}
            trainingData={trainingData}
            handleDayClick={handleDayClick}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
          {selectedLevel === "level1" && (
            <Box
              fullWidth
              sx={{
                padding: 3,
                alignItems: "center",
                border: "1px solid #9CDBAD",
                borderRadius: "16px",
                backgroundColor: "#38B65B"
              }}
            >
              <Box
                width="100%"
                display="flex"
                gap="16px"
                justifyContent="space-between"
                textTransform="none"
                alignItems="center"
              >
                <Box
                  textAlign={"start"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent="space-between"
                >
                  <Box>
                    <Box
                      textAlign="start"
                      sx={{
                        background: "#D61A1A",
                        width: "105px",
                        padding: "2px 6px"
                      }}
                    >
                      <Typography
                        fontSize="9px"
                        textTransform="capitalize"
                        fontWeight="bold"
                        color={"#FCFCFC"}
                      >
                        FIRST 5 DAYS FREE
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        color={"#FCFCFC"}
                        fontSize="1.2rem"
                        fontWeight={600}
                      >
                        {currentLevel?.title}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color={"#FCFCFC"} fontSize="12px">
                        {currentLevel?.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    sx={{
                      mt: 1,
                      height: "23px",
                      textTransform: "none",
                      background: "#FCFCFC",
                      color: "#34C38F",
                      gap: "10px"
                    }}
                  >
                    <Typography fontSize="12px" fontWeight={500}>
                      Buy Now
                    </Typography>
                    <IconButton
                      sx={{
                        background: "#34C38F",
                        borderRadius: "50%",
                        padding: "1px"
                      }}
                    >
                      <ChevronRight
                        sx={{
                          height: 12,
                          width: 12,
                          color: "#FCFCFC"
                        }}
                      />
                    </IconButton>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Box>
            <RenderHtml
              isMobile={isMobile}
              selectedLevel={selectedLevel}
              currentLevel={currentLevel}
              activeLevelForMobile={activeLevelForMobile}
              setIsAudioFinished={setIsAudioFinished}
              isAudioFinished={isAudioFinished}
              styles={styles}
              activeDay={activeDay}
              activeData={currentActiveData}
            />
          </Box>
        </Box>
      )}
      {(isMobile ? selectedLevel && activeLevelForMobile : selectedLevel) && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 16,
            zIndex: 1000
          }}
        >
          <Button
            variant="contained"
            sx={{
              display: "flex",
              backgroundColor: "#506DF0",
              color: styles.color,
              borderRadius: "24px",
              padding: "7px 12px",
              fontSize: "16px",
              textTransform: "none",
              gap: "10px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#2fa273"
              }
            }}
            onClick={() => {
              const url = currentLevel?.link.startsWith("http")
                ? currentLevel?.link
                : `http://${currentLevel?.link}`;
              window.open(url, "_blank");
            }}
          >
            <Typography
              sx={{
                background: "#FCFCFC",
                color: "#506DF0",
                borderRadius: "50%",
                padding: "7px 12px"
              }}
            >
              C
            </Typography>
            <Typography color="#FCFCFC">Join Us</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Programs;
