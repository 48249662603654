import React, { createContext, useContext } from "react";

// Create a context for the user
const UserContext = createContext();

export const useCurrentUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ currentUser, children }) => {
  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
};
