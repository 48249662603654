import styled from "@emotion/styled";
import { CheckCircle, Close, Star } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    border: "2px dotted #A4A4A4",
    "& fieldset": {
      color: "#9CA3AF"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC",
      border: "none"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC",
      border: "none"
    }
  }
}));

const SubscriptionModal = ({ open, onClose }) => {
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [couponModalOpen, setCouponModalOpen] = useState(false);

  const [inputCouponValue, setInputCouponValue] = useState("");

  const handleCouponChange = (event) => {
    // Apply transformations based on CPOVP, REFDS, MNGFQ requirements here
    // For example:
    if (event.target.value.length > 23) return;
    const existing = event.target.value.replaceAll("-", "").toUpperCase();
    // Insert hyphens after every 5 characters
    const formattedValue = existing.replace(/(.{5})(?!-)/g, "$1-");

    setInputCouponValue(formattedValue);
  };

  const handleSubscriptionChange = (event) => {
    setSubscriptionType(event.target.value);
  };

  const handleSubscribe = () => {
    setSnackbarMessage(
      `You have subscribed to the ${
        subscriptionType === "monthly" ? "Monthly" : "Annual"
      } plan.`
    );
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="meal-modal-title"
      aria-describedby="meal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          overflow: "auto",
          height: { xs: "80%", md: "80%" },
          width: { md: "42%", xs: "100%" },
          borderRadius: 2,
          transform: "translate(-50%, -50%)"
        }}
      >
        <Box
          sx={{
            padding: { xs: "28px", md: 0 },
            position: "relative",
            bgcolor: "#000",
            borderRadius: "16px 16px 0 0",
            boxShadow: 24,
            overflow: "hidden" // Ensures pseudo-element stays within the box
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          //   height={"65%"}
          flexDirection={"column"}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: "url(/images/sky.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: { xs: 1, md: 0.5 }, // Set the opacity for the background image
              zIndex: 1 // Ensure it is behind the content
            }}
          />
          <Box
            sx={{
              position: "relative",
              zIndex: 2 // Content stays on top of the background
            }}
          >
            <Box
              display="flex"
              justifyContent="end"
              alignItems="flex-end"
              width="100%"
            >
              <IconButton
                sx={{
                  svg: {
                    fill: "#FCFCFC"
                  }
                }}
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{ padding: { sm: 0, md: "10px 95px" } }}
              display={"flex"}
              gap="10px"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                component="img"
                src="/images/threepillars.png"
                alt="base"
                sx={{
                  width: "68px",
                  height: "68px",
                  objectFit: "cover"
                }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexDirection="column"
              >
                <Typography fontWeight={500} fontSize={"40px"} color="#FCFCFC">
                  3 Pillars Full Access
                </Typography>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={32}>🥗</Typography>
                  <Typography fontSize={"16px"} color="#FCFCFC">
                    <b>Meals of the day:</b> Get inspired with our daily MODs
                    and share yours with the community.
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={32}> 🔊</Typography>
                  <Typography fontSize={"16px"} color="#FCFCFC">
                    <b>30 Day Trainings:</b> Level up your diet with (3) 30 day
                    trainings led by EC from Optimize Nutrition.
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={32}> 📒</Typography>
                  <Typography fontSize={"16px"} color="#FCFCFC">
                    <b> Simplified logging:</b> We make it easy to track your
                    progress through the diet journey.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          height={"40%"}
          sx={{
            background: "#333333",
            display: "flex",
            padding: "24px 47px",
            borderRadius: "0 0 16px 16px",
            flexDirection: "column"
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              value={subscriptionType}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                justifyContent: "center"
              }}
              onChange={handleSubscriptionChange}
            >
              <FormControlLabel
                style={{
                  backgroundColor: "#1C1C1C",
                  borderRadius: "8px",
                  padding: 20,
                  margin: 0,
                  height: "100%"
                }}
                value="monthly"
                control={
                  <Radio
                    icon={<CheckCircle sx={{ fill: "#FCFCFC" }} />}
                    checkedIcon={<CheckCircle sx={{ fill: "#38B65B" }} />}
                  />
                }
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      color: "#FCFCFC"
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      $9.99/Month
                    </Typography>
                    <Typography variant="body2" fontSize="12px">
                      Monthly Subscription
                    </Typography>
                  </Box>
                }
                sx={{
                  border:
                    subscriptionType === "monthly"
                      ? "2px solid #38B65B"
                      : "none",
                  borderRadius: "4px",
                  padding: 1,
                  marginBottom: 2,
                  backgroundColor:
                    subscriptionType === "monthly" ? "#2B2B2B" : "transparent",
                  "&:hover": {
                    backgroundColor: "#2B2B2B"
                  }
                }}
              />
              <FormControlLabel
                style={{
                  backgroundColor: "#1C1C1C",
                  borderRadius: "8px",
                  margin: 0,
                  height: "100%",
                  padding: 1
                }}
                value="annual"
                control={
                  <Radio
                    icon={<CheckCircle sx={{ fill: "#FCFCFC" }} />}
                    checkedIcon={<CheckCircle sx={{ fill: "#38B65B" }} />}
                  />
                }
                label={
                  <>
                    {" "}
                    <Box
                      display="flex"
                      justifyContent="end"
                      width="100%"
                      mb={0.4}
                      alignItems="center"
                    >
                      <Typography
                        display={"flex"}
                        sx={{
                          background: "#FCFCFC",
                          padding: "0 8px",
                          alignItems: "center",
                          gap: "5px",
                          borderRadius: "0 8px 0 8px"
                        }}
                        color="black"
                      >
                        <Star sx={{ fill: "#F1B44C", fontSize: "14px" }} />
                        <Typography variant="caption" lineHeight={0}>
                          Save 18%
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "0 20px 20px 20px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        color: "#FCFCFC"
                      }}
                    >
                      {" "}
                      <Typography variant="h6" fontWeight="bold">
                        $99.00/Year
                      </Typography>
                      <Typography variant="body2" fontSize="12px">
                        Annual Subscription
                      </Typography>
                    </Box>
                  </>
                }
                sx={{
                  border:
                    subscriptionType === "annual"
                      ? "2px solid #38B65B"
                      : "none",
                  borderRadius: "8px",
                  padding: 1,
                  marginBottom: 2,
                  backgroundColor:
                    subscriptionType === "annual" ? "#2B2B2B" : "transparent",
                  "&:hover": {
                    backgroundColor: "#2B2B2B"
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          <Box mt={4} display="flex" justifyContent="center">
            <Button
              sx={{
                width: { xs: "100%", md: "70%" },
                backgroundColor: "#38B65B",
                textTransform: "none",
                fontWeight: "bold"
              }}
              variant="contained"
              onClick={handleSubscribe}
            >
              Subscribe
            </Button>
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              sx={{
                width: "70%",
                color: "#38B65B",
                textTransform: "none",
                fontWeight: "bold"
              }}
              variant="none"
              onClick={() => {
                setCouponModalOpen(true);
              }}
            >
              Apply Coupon
            </Button>
          </Box>

          {couponModalOpen && (
            <Modal
              open={couponModalOpen}
              onClose={() => {
                setCouponModalOpen(false);
                onClose();
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  height: { xs: "35%", md: "30%" },
                  width: { xs: "65%", md: "30%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: 3,
                  background: "#1C1C1C",
                  color: "#FCFCFC",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <Box
                  display="flex"
                  justifyContent="end"
                  alignItems="flex-end"
                  width="100%"
                >
                  <IconButton
                    sx={{
                      svg: {
                        fill: "#FCFCFC"
                      }
                    }}
                    onClick={() => {
                      setCouponModalOpen(false);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <Typography fontSize={"24px"} fontWeight={600}>
                  Apply Coupon
                </Typography>
                <Typography fontSize={"12px"} fontWeight={400} color="#D1D5DB">
                  Please enter the coupon code to activate the subscription
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={3}
                  width={"100%"}
                  padding={2}
                >
                  <StyledTextField
                    fullWidth
                    value={inputCouponValue}
                    variant="outlined"
                    name="coupon"
                    placeholder="Enter the coupon code"
                    margin="normal"
                    onChange={handleCouponChange}
                  />
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: "#38B65B",
                      textTransform: "none",
                      fontWeight: "bold"
                    }}
                    variant="contained"
                    onClick={handleSubscribe}
                  >
                    Apply now
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscriptionModal;
