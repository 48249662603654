import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addNewModData, getCurrentMeal } from "../../api/modApi";

// Async thunk for adding a new meal
export const addNewMod = createAsyncThunk(
  "mod/addNewMealOfDay",
  async (mealData, thunkAPI) => {
    try {
      const response = await addNewModData(mealData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Async thunk for fetching all meals with pagination
export const getCurrentDateMeal = createAsyncThunk(
  "mod/getCurrentDateMeal",
  async ({ currentDate }, thunkAPI) => {
    try {
      const response = await getCurrentMeal(currentDate);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  currentMeal: {}, // holds the meals data
  loading: false, // loading state for getCurrentDateMeal
  error: null, // error state
  isLiked: false,
  isFavorite: false
};

const modSlice = createSlice({
  name: "mod",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handling addNewMod actions
      // .addCase(addNewMod.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(addNewMod.fulfilled, (state, action) => {
      //   state.loading = false;
      //   // Assuming that addNewMod will return an updated meal list, update the state
      //   state.mealData = action.payload;
      // })
      // .addCase(addNewMod.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

      // Handling getCurrentDateMeal actions
      .addCase(getCurrentDateMeal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentDateMeal.fulfilled, (state, action) => {
        state.loading = false;
        state.currentMeal = action.payload.meals?.[0]; // assuming response has meals data
        state.isLiked = action.payload.likedByUser;
        state.isFavorite = action.payload.favouriteByUser;
      })
      .addCase(getCurrentDateMeal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default modSlice.reducer;
