import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  completeDiary,
  getAllUserDiaries,
  getAllDiaryUserLevels,
  getAllDiaryUserStats,
  getLeaderBoardData
} from "../../api/diaryApi";

export const getCurrentUserDiaries = createAsyncThunk(
  "diary/getCurrentUserDiaries",
  async (levelId, thunkAPI) => {
    try {
      const response = await getAllUserDiaries(levelId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const completeDiaryDay = createAsyncThunk(
  "diary/completeDiaryDay",
  async (data, thunkAPI) => {
    try {
      const response = await completeDiary(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserDiaryLevels = createAsyncThunk(
  "diary/getCurrentUserDiaryLevels",
  async (_, thunkAPI) => {
    try {
      const response = await getAllDiaryUserLevels();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserDiaryStats = createAsyncThunk(
  "diary/getCurrentUserDiaryStats",
  async (data, thunkAPI) => {
    try {
      const response = await getAllDiaryUserStats(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentLevelLeaderboard = createAsyncThunk(
  "diary/getCurrentLevelLeaderboard",
  async ({ levelId, month, year }, thunkAPI) => {
    try {
      const response = await getLeaderBoardData(levelId, month, year);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  allDiaries: [],
  availableDiaryLevels: [],
  stats: [],
  outOf800g: "800",
  outOfProtein: "800",
  leaderboard: [],
  loading: false, // loading state for getCurrentUserLevels
  error: null // error state
};

const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserDiaryLevels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaryLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.availableDiaryLevels = action.payload.diaryLevels; // assuming response has meals data
      })
      .addCase(getCurrentUserDiaryLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUserDiaries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaries.fulfilled, (state, action) => {
        state.loading = false;
        state.allDiaries = action.payload.diary; // assuming response has meals data
      })
      .addCase(getCurrentUserDiaries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUserDiaryStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaryStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload.diaries; // assuming response has meals data
        state.outOf800g = action.payload.outOf800g;
        state.outOfProtein = action.payload.outOfProtein;
      })
      .addCase(getCurrentUserDiaryStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentLevelLeaderboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentLevelLeaderboard.fulfilled, (state, action) => {
        state.loading = false;
        state.leaderboard = action.payload.leaderboard; // assuming response has meals data
      })
      .addCase(getCurrentLevelLeaderboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// export const { updateTrainingById, updatePercentage } = diarySlice.actions;

export default diarySlice.reducer;
