import React from "react";
import { CircularProgress, Typography, Box } from "@mui/material";

const CircularProgressStatic = ({ levelName, selected, percentage }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      // mr={levelName !== "level1" ? "10px" : 0}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        size={67} // Set size for the outer circle
        sx={{
          color: "#9CDBAD",
          position: "absolute",
          borderRadius: "50%" // Ensures it's circular
        }}
      />
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={67} // Set size for the inner circle
        sx={{
          color: selected ? "#FCFCFC" : "#38B65B",
          position: "absolute",
          borderRadius: "50%" // Ensures it's circular
        }}
      />
      <Typography
        fontSize="18px" // Increase font size for better visibility
        color={"#FCFCFC"}
        fontWeight={600}
        sx={{ position: "relative", zIndex: 1 }} // Ensure text is above the circles
      >
        {`${Math.round(percentage)}%`}
      </Typography>
    </Box>
  );
};

export default CircularProgressStatic;
