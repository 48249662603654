import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as TPillars } from "../assets/mainlogo.svg";
import { ReactComponent as DiaryIcon } from "../assets/diary.svg";
import { ReactComponent as ModIcon } from "../assets/mod.svg";
import { ReactComponent as MealsIcon } from "../assets/meals.svg";
import { ReactComponent as ProfileIcon } from "../assets/profile.svg";
import { ReactComponent as ProgramIcon } from "../assets/book.svg";
import { ReactComponent as PremiumIcon } from "../assets/premium.svg";
import {
  Button,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Badge
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import SubscriptionModal from "../components/Modals/SubscriptionModal";
import { useCurrentUser } from "../components/Extra/UserContext";

const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  height: "92px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(4, 1),
  ...theme.mixins.toolbar,
  position: "relative" // Add relative position for absolute positioning of the toggle button
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth - 20,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "visible",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflow: "visible"
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflow: "visible"
    }
  })
}));

const AdminDashboard = ({ activeLevelForMobile, open, setOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);
  const menuItems = [
    { text: "MOD", icon: <ModIcon />, path: "/" },
    { text: "Diary", icon: <DiaryIcon />, path: "/diary" },
    { text: "Programs", icon: <ProgramIcon />, path: "/programs" },
    { text: "Meals", icon: <MealsIcon />, path: "/meals" },
    { text: "Profile", icon: <ProfileIcon />, path: "/profile" }
  ];
  const currentUser = useCurrentUser();

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ".MuiDrawer-paper": {
            display: "flex",
            justifyContent: "space-between",
            background: "#1C1C1C",
            color: "#9CA3AF"
          },
          display: { xs: "none", sm: "block" }
        }}
      >
        <Box>
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              display="flex"
              sx={{
                width: "85%",
                height: "auto"
              }}
              gap="13px"
            >
              <Box height={55} component="img" src="/images/threepillars.png" />
              <TPillars
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </Box>
            {/* Toggle Button for Drawer */}
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                position: "absolute",
                padding: 0,
                top: "50%",
                right: -12, // Position based on drawer state
                transform: "translateY(-50%)",
                backgroundColor: "#1F2937",
                color: "#9CA3AF",
                "&:hover": {
                  backgroundColor: "#333333"
                }
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={{ marginTop: "20px" }}>
            {menuItems.map(({ text, icon, path }) => (
              <ListItem key={text} sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => navigate(path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    ...(location.pathname === path && {
                      background: "#1F2937",
                      borderRadius: "4px"
                    })
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      position: "relative", // Make icon positioning relative
                      ...(location.pathname === path
                        ? !["Meals", "Profile"].includes(text)
                          ? {
                              "& svg path": {
                                fill: "#38B65B"
                              }
                            }
                          : {
                              "& svg path": {
                                stroke: "#38B65B",
                                fill: "none"
                              }
                            }
                        : {})
                    }}
                  >
                    {text === "Programs" &&
                    !open &&
                    currentUser?.pendingTrainingDays ? (
                      <Badge
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#FFF",
                            backgroundColor: "#F6541C",
                            position: "absolute", // Set badge position as absolute
                            top: open ? 0 : 2, // Adjust based on drawer state
                            left: open ? "60px" : "20px" // Adjust badge positioning for closed state
                          }
                        }}
                        badgeContent={currentUser?.pendingTrainingDays}
                      >
                        {icon}
                      </Badge>
                    ) : (
                      icon
                    )}
                  </ListItemIcon>
                  {text === "Programs" &&
                  open &&
                  currentUser?.pendingTrainingDays ? (
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#FFF",
                          backgroundColor: "#F6541C",
                          left: "60px"
                        }
                      }}
                      badgeContent={currentUser?.pendingTrainingDays}
                    >
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: open ? 1 : 0,
                          ...(location.pathname === path && {
                            color: "#38B65B"
                          })
                        }}
                      />
                    </Badge>
                  ) : (
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        ...(location.pathname === path && {
                          color: "#38B65B"
                        })
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        {open && (
          <Box
            margin="0 16px 40px 16px"
            alignItems={"center"}
            justifyContent={"center"}
            display="flex"
            flexDirection="column"
            sx={{
              background: "#333333",
              padding: "20px",
              color: "#9CA3AF",
              borderRadius: "4px",
              display: { xs: "none", sm: "flex" }
            }}
          >
            <PremiumIcon />
            <Typography mt={2} fontSize="16px" fontWeight={600} color="#FCFCFC">
              3 Pillar full Access
            </Typography>
            <Typography fontSize="12px" fontWeight={400} color="#FCFCFC">
              Get all benefits
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              style={{
                textTransform: "none",
                color: "#38B65B",
                borderColor: "#38B65B",
                marginTop: "10px"
              }}
              onClick={() => setOpenSubscriptionModal(true)}
            >
              View Plans
            </Button>
          </Box>
        )}
      </Drawer>
      <SubscriptionModal
        open={openSubscriptionModal}
        onClose={() => setOpenSubscriptionModal(false)}
      />
      {(location.pathname === "/programs"
        ? isMobile && !activeLevelForMobile
        : isMobile) && (
        <BottomNavigation
          showLabels
          sx={{
            height: "60px",
            display: { xs: "flex", sm: "none" },
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 1000,
            backgroundColor: "#333333",
            svg: { path: { fill: "#9CA3AF" } }
          }}
          value={location.pathname}
          onChange={(event, newValue) => {
            navigate(newValue);
          }}
        >
          {menuItems.map(({ text, icon, path }) => (
            <BottomNavigationAction
              key={text}
              label={text}
              icon={
                text === "Programs" && currentUser?.pendingTrainingDays ? (
                  <Badge
                    badgeContent={currentUser?.pendingTrainingDays}
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#FFF",
                        backgroundColor: "#F6541C",
                        right: -10, // Adjusts badge position
                        top: -5 // Adjusts badge position
                      }
                    }}
                  >
                    {icon}
                  </Badge>
                ) : (
                  icon
                )
              }
              value={path}
              sx={{
                "&.MuiButtonBase-root ": {
                  gap: "4px",
                  ...(location.pathname === path
                    ? !["Meals", "Profile"].includes(text)
                      ? {
                          "& svg path": {
                            fill: "#38B65B"
                          }
                        }
                      : {
                          "& svg path": {
                            stroke: "#38B65B",
                            fill: "none" // Optional: Set fill to none for these two
                          }
                        }
                    : {})
                },
                color: "#9CA3AF",
                ...(location.pathname === path && {
                  color: "#38B65B !important"
                })
              }}
            />
          ))}
        </BottomNavigation>
      )}
    </>
  );
};

export default AdminDashboard;
