import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { ReactComponent as ThanksIcon } from "../../assets/thanks.svg";
import useThemeColor from "../../utils/useTheme";

const ThankyouModal = ({ openThanksModal, handleCloseThanksModal, goBack }) => {
  const styles = useThemeColor();
  return (
    <Dialog
      sx={{
        ".MuiDialog-paper": {
          background: styles.background,
          padding: 2,
          width: "400px",
          color: styles.color,
          position: "relative"
        }
      }}
      open={openThanksModal}
      onClose={() => {}}
      aria-labelledby="thanks-modal-title"
      aria-describedby="thanks-modal-description"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        p={2}
      >
        <ThanksIcon />
        <DialogTitle
          id="thanks-modal-title"
          fontSize="30px"
          mb={0}
          fontWeight="500"
        >
          Thank You!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={styles.color}
            fontSize="16px"
            id="thanks-modal-description"
          >
            Your MOD has been submitted.
          </DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            goBack(false);
            handleCloseThanksModal(false);
          }}
          sx={{
            textTransform: "none",
            color: "#38B65B",
            borderColor: "#38B65B",
            mt: 1, // Reduced margin top
            gap: "8px",
            borderRadius: "4px"
          }}
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThankyouModal;
