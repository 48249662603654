import React from "react";
import { Card, Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const CustomRadio = ({ label, checked, onChange, value, styles }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box onClick={() => onChange(value)} sx={{ cursor: "pointer" }}>
      <Card
        sx={{
          backgroundColor: styles.lightGreyColor,
          "&:hover": {
            backgroundColor: styles.disabledColor,
            color: styles.background
          },
          transition: "background-color 0.3s",
          padding: 2,
          display: "flex",
          alignItems: "center",
          gap: 2
        }}
      >
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: checked ? "#38B65B" : "transparent",
            border: checked ? "none" : "2px solid grey"
          }}
        >
          {checked && (
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: "white",
                borderRadius: "50%"
              }}
            />
          )}
        </Box>
        <Typography
          fontSize={isMobile ? "13px" : "15px"}
          color={styles.color}
          variant="body1"
        >
          {label}
        </Typography>
      </Card>
    </Box>
  );
};

export default CustomRadio;
