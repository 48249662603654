import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAuthenticated
} from "../../features/auth/authSelectors";
import { fetchCurrentUser } from "../../features/auth/authSlice";
import dayjs from "dayjs";
import { UserProvider } from "../Extra/UserContext";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);
  useEffect(() => {
    dispatch(fetchCurrentUser(dayjs().format("YYYY-MM-DD")));
  }, [dispatch]);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <UserProvider currentUser={currentUser} children={children} />;
};

export default PrivateRoute;
