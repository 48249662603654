import axios from "axios";

// Helper function to get the token from localStorage
const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `${token}` } : {};
};

// Function to create an axios instance with optional authHeader
const createApi = (authRequired = false) => {
  const headers = authRequired ? getAuthHeader() : {};
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      ...headers,
      Accept: "application/json, text/plain, */*",
      "ngrok-skip-browser-warning": true
    }
  });
};

export default createApi;
