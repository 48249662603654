import createApi from "../utils/axiosInstance";

// Login API
export const login = async (credentials) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/login", credentials);

    if (response.data) {
      const { user, token } = response.data;
      // const hours = expiresIn?.split("h")[0];
      // const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem("expirationTime", expirationTime);
    }

    return response.data;
  } catch (error) {
    console.error(
      "Login failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Register API
export const register = async (userData) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/signup", userData);
    if (response.data) {
      const { user, token } = response.data;
      // const hours = expiresIn?.split("h")[0];
      // const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem("expirationTime", expirationTime);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Registration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Logout API
export const logout = async () => {
  try {
    // Remove token from localStorage on logout
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // localStorage.removeItem("expirationTime");
    return { message: "Success! You can now login again" };
  } catch (error) {
    console.error(
      "Logout failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const forgetPassword = async (email) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/forgotPassword", email);

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("expirationTime");
    return response.data;
  } catch (error) {
    console.error(
      "Forget password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const resetPassword = async (userData) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/resetPassword", userData);
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
