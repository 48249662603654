import {
  Bookmark,
  BookmarkBorder,
  Favorite,
  FavoriteBorder
} from "@mui/icons-material";
import { Avatar, Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import getInitials from "../../utils/getInitials";
import { formatDate } from "../../utils/formatDate";
import useSignedUrl from "../../utils/useSignedUrl";
import PreviewModal from "../Modals/PreviewMealModal";
import useThemeColor from "../../utils/useTheme";

const MealCard = ({
  currentTab,
  meal,
  isFromViewingMeal = false,
  setIsViewingUserMeals,
  onLikeToggle,
  onFavourite,
  setIsRefresh
}) => {
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imgSrc = useSignedUrl(
    meal?.createdByUser?.profilePic || meal?.createdByAdminUser?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );
  const [openPreview, setOpenPreview] = React.useState(null);
  const mealImageSrc = useSignedUrl(
    meal?.mealImage,
    process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION
  );
  const styles = useThemeColor();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={"4px"}
      sx={{
        ":hover": {
          cursor: "pointer"
        }
      }}
      position="relative"
      width={isMobile ? "160px" : "200px"}
      onClick={() => {
        setOpenPreview(meal);
      }}
    >
      <Box
        component="img"
        src={mealImageSrc}
        height={isMobile ? "160px" : "200px"}
        width={isMobile ? "160px" : "200px"}
        borderRadius={1}
        position="relative"
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          onClick={async (e) => {
            e.stopPropagation();
            // if (isFromViewingMeal || currentTab === "popular") return;
            onLikeToggle(meal?._id);
          }}
          sx={{
            padding: "5px",
            display: "flex",
            gap: "4px",
            borderRadius: "10px"
          }}
        >
          {meal?.likedByUser ? (
            <Favorite
              sx={{
                height: "20px",
                width: "20px",
                fill: "#E94545"
              }}
            />
          ) : (
            <FavoriteBorder
              sx={{
                height: "20px",
                width: "20px",
                fill: styles.color
              }}
            />
          )}
          <Typography fontWeight={500} color={styles.color} fontSize={"12px"}>
            {meal?.likeCount}
          </Typography>
        </Box>
        <Typography color="#6B7280" fontSize="12px">
          {formatDate(meal?.scheduledDate)}
        </Typography>
        <Box
          onClick={async (e) => {
            e.stopPropagation();
            await onFavourite(meal?._id);
          }}
          sx={{
            padding: "5px",
            display: "flex",
            gap: "4px",
            borderRadius: "10px"
          }}
        >
          {meal?.favouriteByUser ? (
            <Bookmark
              sx={{
                height: "20px",
                width: "20px",
                fill: styles.color
              }}
            />
          ) : (
            <BookmarkBorder
              sx={{
                height: "20px",
                width: "20px",
                fill: styles.color
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: isMobile ? "160px" : "200px", // Ensure the width is constrained
          whiteSpace: "nowrap", // Prevent text wrapping
          overflow: "hidden", // Hide overflowing text
          textOverflow: "ellipsis" // Show ellipsis when text overflows
        }}
      >
        <Typography fontWeight={600} fontSize="14px" noWrap>
          {meal?.title}
        </Typography>
        <Typography display="flex" fontWeight={600} fontSize="14px">
          ({" "}
          <Typography fontWeight={600} fontSize="14px" color="#F6541C">
            {meal?.total800Gms}g
          </Typography>{" "}
          /{" "}
          <Typography fontWeight={600} fontSize="14px" color="#2C75C5">
            {meal?.totalProteinGms}g
          </Typography>{" "}
          )
        </Typography>
      </Box>

      {!isFromViewingMeal && (
        <Box
          display="flex"
          gap="5px"
          alignItems="center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsViewingUserMeals(meal);
          }}
        >
          {meal?.createdByUser?.profilePic ||
          meal?.createdByAdminUser?.profilePic ? (
            <Box
              component="img"
              height={25}
              borderRadius="50%"
              border="0.5px solid #38B65B"
              width={25}
              src={imgSrc}
              alt="avatar"
              bottom="-10px"
            />
          ) : (
            <Avatar
              sx={{
                width: 25,
                height: 25,
                borderRadius: "50%",
                border: "0.5px solid #38B65B"
              }}
            >
              <Typography color="#FCFCFC" fontSize="11px" fontWeight={400}>
                {getInitials(
                  meal?.createdByAdminUser
                    ? meal?.createdByAdminUser?.name
                    : meal?.createdByUser?.fullName
                )}
              </Typography>
            </Avatar>
          )}
          <Typography fontSize="12px">
            {meal?.createdByAdminUser
              ? meal?.createdByAdminUser?.name
              : meal?.createdByUser?.fullName}
          </Typography>
        </Box>
      )}
      <PreviewModal
        onClose={() => {
          setOpenPreview(null);
          setIsRefresh(true);
        }}
        currentMeal={openPreview}
      />
    </Box>
  );
};

export default MealCard;
