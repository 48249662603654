import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers";
import useThemeColor from "../../utils/useTheme";

// Custom PickersDay component for day styling
const CustomPickersDay = ({ completedDates, ...props }) => {
  const isCompleted = completedDates?.includes(
    dayjs(props.day).format("YYYY-MM-DD")
  );
  const styles = useThemeColor();
  return (
    <PickersDay
      {...props}
      sx={{
        ...props.sx,
        borderRadius: isCompleted ? "50% !important" : "4px",
        border: isCompleted ? `1px solid ${styles.primaryColor}` : "none",
        "&.Mui-selected": {
          backgroundColor: `${styles.primaryColor} !important`
        }
      }}
    />
  );
};

// Custom Styles for the Calendar popper
const calendarStyles = (styles) => ({
  "& .MuiPaper-root": {
    backgroundColor: styles.background,
    color: "white",
    ".MuiPickersLayout-root": {
      display: "block",
      ".MuiPickersLayout-contentWrapper": {
        ".MuiDateCalendar-root": {
          height: "100%",
          ".MuiYearCalendar-root": {
            height: "100%",
            "& .MuiPickersYear-yearButton": {
              color: styles.color,
              borderRadius: "4px",
              "&.Mui-selected": {
                backgroundColor: styles.primaryColor,
                "&:hover": {
                  backgroundColor: styles.primaryColor
                }
              },
              "&.Mui-disabled": {
                color: styles.disabledColor
              }
            }
          },
          ".MuiPickersSlideTransition-root ": {
            minHeight: "200px",
            ".MuiPickersDay-root": {
              color: styles.color,
              borderRadius: "4px",
              "&.Mui-disabled": {
                color: styles.disabledColor
              }
            }
          }
        }
      }
    }
  },
  ".MuiPickersCalendarHeader-label": {
    color: styles.primaryColor
  },
  "& .MuiDayCalendar-weekDayLabel": {
    color: styles.color
  },
  "& .MuiPickersCalendarHeader-switchViewButton": {
    color: "white"
  },
  "& .MuiPickersArrowSwitcher-button": {
    color: styles.color,
    "&.Mui-disabled": {
      color: "#9e9e9e !important",
      opacity: 1
    }
  },
  "& .MuiPickersYear-root": {
    color: styles.color
  },
  "& .MuiPickersMonth-root": {
    color: styles.color,
    borderRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: styles.primaryColor,
      "&:hover": {
        backgroundColor: styles.primaryColor
      }
    },
    "&.Mui-disabled": {
      color: styles.disabledColor
    }
  },
  "& .MuiYearCalendar-root": {
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-track": {
      background: styles.background
    },
    "&::-webkit-scrollbar-thumb": {
      background: styles.primaryColor,
      borderRadius: "4px"
    }
  }
});

function DateRangePicker({ startDate, setStartDate, endDate, setEndDate }) {
  const styles = useThemeColor();

  // Handle start date change
  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);

    // If the end date is before the new start date, reset the end date
    if (endDate && newStartDate && newStartDate.isAfter(endDate)) {
      setEndDate(null);
    }
  };

  // Handle end date change
  const handleEndDateChange = (newEndDate) => {
    if (startDate && newEndDate.isBefore(startDate)) {
      // Do not allow end date to be before the start date
      alert("End date cannot be before the start date.");
      return;
    }
    setEndDate(newEndDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          ".MuiFormLabel-root": {
            color: styles.inputLabelColor
          },
          ".MuiTextField-root": {
            border: `1px solid ${styles.lightGreyColor}`,
            borderRadius: "4px"
          },
          ".MuiInputBase-root": {
            input: {
              color: styles.color
            }
          }
        }}
      >
        {/* Start Date Picker */}
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          //   renderInput={(params) => <Button {...params} />}
          disableFuture
          sx={{
            color: styles.color // Label color for Start Date
          }}
          InputProps={{
            style: { color: styles.color, border: `1px solid ${styles.color}` } // Apply color to the label
          }}
          slots={{
            day: (dayProps) => (
              <CustomPickersDay {...dayProps} completedDates={[]} />
            ),
            openPickerIcon: () => <CalendarIcon sx={{ color: styles.color }} />
          }}
          slotProps={{
            popper: {
              sx: calendarStyles(styles)
            }
          }}
        />

        {/* End Date Picker */}
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <Button {...params} />}
          disableFuture
          minDate={startDate} // Prevent selecting an end date before the start date
          sx={{
            color: styles.color // Label color for End Date
          }}
          InputProps={{
            style: { color: styles.color } // Apply color to the label
          }}
          slots={{
            day: (dayProps) => (
              <CustomPickersDay {...dayProps} completedDates={[]} />
            ),
            openPickerIcon: () => <CalendarIcon sx={{ color: styles.color }} />
          }}
          slotProps={{
            popper: {
              sx: calendarStyles(styles)
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangePicker;
