import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import useThemeColor from "../../utils/useTheme";

// Custom Day component to handle highlighting
const CustomPickersDay = ({ completedDates, ...props }) => {
  const isCompleted = completedDates?.includes(
    dayjs(props.day).format("YYYY-MM-DD")
  );
  const styles = useThemeColor();
  return (
    <PickersDay
      {...props}
      sx={{
        ...props.sx,
        borderRadius: isCompleted ? "50% !important" : "4px",
        border: isCompleted ? `1px solid ${styles.primaryColor}` : "none",
        "&.Mui-selected": {
          backgroundColor: `${styles.primaryColor} !important`
        }
      }}
    />
  );
};

const calendarStyles = (styles) => ({
  "& .MuiPaper-root": {
    backgroundColor: styles.background,
    color: "white",
    ".MuiPickersLayout-root": {
      display: "block",
      ".MuiPickersLayout-contentWrapper": {
        ".MuiDateCalendar-root": {
          height: "100%",
          ".MuiYearCalendar-root": {
            height: "100%",
            "& .MuiPickersYear-yearButton": {
              color: styles.color,
              borderRadius: "4px",
              "&.Mui-selected": {
                backgroundColor: styles.primaryColor,
                "&:hover": {
                  backgroundColor: styles.primaryColor
                }
              },
              "&.Mui-disabled": {
                color: styles.disabledColor
              }
            }
          },
          ".MuiPickersSlideTransition-root ": {
            minHeight: "200px",
            ".MuiPickersDay-root": {
              color: styles.color,
              borderRadius: "4px",
              "&.Mui-disabled": {
                color: styles.disabledColor
              }
            }
          }
        }
      }
    }
  },
  ".MuiPickersCalendarHeader-label": {
    color: styles.primaryColor
  },
  "& .MuiDayCalendar-weekDayLabel": {
    color: styles.color
  },
  "& .MuiPickersCalendarHeader-switchViewButton": {
    color: "white"
  },
  "& .MuiPickersArrowSwitcher-button": {
    color: styles.color,
    "&.Mui-disabled": {
      color: "#9e9e9e !important",
      opacity: 1
    }
  },
  "& .MuiPickersYear-root": {
    color: styles.color
  },
  "& .MuiPickersMonth-root": {
    color: styles.color,
    borderRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: styles.primaryColor,
      "&:hover": {
        backgroundColor: styles.primaryColor
      }
    },
    "&.Mui-disabled": {
      color: styles.disabledColor
    }
  },
  "& .MuiYearCalendar-root": {
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-track": {
      background: styles.background
    },
    "&::-webkit-scrollbar-thumb": {
      background: styles.primaryColor,
      borderRadius: "4px"
    }
  }
});

function ButtonField(props) {
  const {
    setOpen,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    currentMonthLabel
  } = props;
  const styles = useThemeColor();

  return (
    <Button
      sx={{
        textTransform: "none",
        color: styles.color,
        fontSize: "17px"
      }}
      ref={ref}
      endIcon={<ArrowDropDownIcon />}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {currentMonthLabel}
    </Button>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useThemeColor();
  return (
    <DatePicker
      slots={{
        ...props.slots,
        field: ButtonField,
        day: (dayProps) => (
          <CustomPickersDay
            {...dayProps}
            completedDates={props.allCompletedDates}
          />
        ),
        ...(!isMobile && {
          actionBar: () => (
            <Box
              sx={{
                width: "100%",
                borderTop: "1px solid rgba(255,255,255,0.12)"
              }}
            >
              <Button
                onClick={() => {
                  const today = dayjs();
                  props.onChange(today);
                  setOpen(false);
                }}
                sx={{
                  color: styles.primaryColor,
                  width: "100%",
                  padding: "8px",
                  "&:hover": {
                    backgroundColor: "transparent"
                  }
                }}
              >
                Today
              </Button>
            </Box>
          )
        })
      }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen, currentMonthLabel: props.currentMonthLabel },
        popper: {
          sx: calendarStyles(styles)
        }
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function PickerWithButtonField({
  date,
  setDate,
  activeDay,
  maxDate,
  setActiveDay,
  visibleDaysCount,
  setStartDay,
  allCompletedDates
}) {
  const [currentMonthLabel, setCurrentMonthLabel] = useState(
    dayjs(activeDay).format("MMMM")
  );
  useEffect(
    () => setCurrentMonthLabel(dayjs(activeDay).format("MMMM")),
    [activeDay]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        label={null}
        value={date}
        maxAllowedDate={maxDate}
        allCompletedDates={allCompletedDates}
        currentMonthLabel={currentMonthLabel}
        shouldDisableDate={(date) => {
          const today = dayjs();
          const formattedDate = dayjs(date);
          return (
            formattedDate.isBefore(maxDate, "day") ||
            formattedDate.isAfter(today, "day")
          );
        }}
        onChange={async (newValue) => {
          setDate(newValue);
          setActiveDay(dayjs(newValue).format("YYYY-MM-DD"));
          setCurrentMonthLabel(dayjs(newValue).format("MMMM"));
          const currentDay = dayjs(newValue).diff(maxDate, "day");
          const pageIndex = Math.floor(currentDay / visibleDaysCount);
          const calculatedStartDay = pageIndex * visibleDaysCount + 1;
          setStartDay(calculatedStartDay);
        }}
      />
    </LocalizationProvider>
  );
}
