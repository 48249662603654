import createApi from "../utils/axiosInstance";

export const addNewModData = async (mealData) => {
  try {
    const api = createApi(true);

    const response = await api.post(`/admin/meal/add-meal`, mealData);
    return response.data;
  } catch (error) {
    console.error(
      "New mod data registeration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getCurrentMeal = async (currentDate) => {
  try {
    const api = createApi(true);

    const response = await api.get(`/user/get-user-meal?date=${currentDate}`);
    return response.data;
  } catch (error) {
    console.error(
      "New mod data registeration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const favoriteMeal = async (mealId) => {
  try {
    const api = createApi(true);

    const response = await api.post(`/user/favourite-meal`, { mealId });
    return response.data;
  } catch (error) {
    console.error(
      "Favorite meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const likeMeal = async (mealId) => {
  try {
    const api = createApi(true);

    const response = await api.post(`/user/like-meal`, { mealId });
    return response.data;
  } catch (error) {
    console.error(
      "Like meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
