// modSelectors.js

export const selectMealData = (state) => state.mod.currentMeal; // Selector to get meals data
export const selectIsFavorite = (state) => state.mod.isFavorite; // Selector to get meals data
export const selectIsLiked = (state) => state.mod.isLiked; // Selector to get meals data
export const selectLoading = (state) => state.mod.loading; // Selector to get loading state
export const selectTotal = (state) => state.mod.total; // Selector for total pages
export const selectError = (state) => state.mod.error; // Selector for error state
export const selectCurrentPage = (state) => state.mod.page;
export const selectLimit = (state) => state.mod.limit;
export const selectScheduledDates = (state) => state.mod.availableDates;
