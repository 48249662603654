import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, IconButton, Slider } from "@mui/material";

const AudioPlayer = ({ src, styles, setIsAudioFinished, isSliderNeeded }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  // Toggle play/pause
  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Toggle mute
  const toggleMute = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  // Seek forward 10 seconds
  const seekForward = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = Math.min(audio.currentTime + 10, duration);
      setCurrentTime(audio.currentTime);
    }
  };

  // Seek backward 10 seconds
  const seekBackward = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = Math.max(audio.currentTime - 10, 0);
      setCurrentTime(audio.currentTime);
    }
  };

  // Update duration and handle events when audio is loaded
  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setCurrentTime(0);
      setIsPlaying(false);
      setIsAudioFinished(true);
    };

    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("ended", handleEnded);
      }
    };
  }, [src]);

  // Handle progress bar change
  const handleSliderChange = (event, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = newValue;
    setCurrentTime(newValue);
  };

  return (
    <Box display="flex" alignItems="center" gap="12px" width="100%">
      <IconButton sx={{ p: 0 }} onClick={seekBackward} disabled={!duration}>
        <Replay10Icon sx={{ color: styles.lightGreyColor }} />
      </IconButton>
      <IconButton
        sx={{
          padding: "4px",
          borderRadius: "50%",
          background: "#38B65B",
          color: "#FCFCFC",
          ":hover": {
            color: "#38B65B",
            background: "#FCFCFC"
          },
          ":disabled": {
            backgroundColor: "#38B65B",
            color: "#9CDBAD",
            pointerEvents: "none"
          }
        }}
        onClick={togglePlay}
        disabled={!duration}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={seekForward} disabled={!duration}>
        <Forward10Icon sx={{ color: styles.lightGreyColor }} />
      </IconButton>
      {isSliderNeeded && (
        <Slider
          value={currentTime}
          max={duration}
          onChange={handleSliderChange}
          sx={{
            color: "#38B65B",
            flex: 1,
            height: 4,
            borderRadius: 4,
            "& .MuiSlider-thumb": {
              display: "none"
            },
            "& .MuiSlider-track": {
              backgroundColor: "#38B65B"
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#E1F5E6"
            }
          }}
        />
      )}
      <IconButton sx={{ p: 0 }} onClick={toggleMute} disabled={!duration}>
        {isMuted ? (
          <VolumeOffIcon sx={{ color: styles.lightGreyColor }} />
        ) : (
          <VolumeUpIcon sx={{ color: styles.lightGreyColor }} />
        )}
      </IconButton>
      <audio ref={audioRef} src={src} />
    </Box>
  );
};

export default AudioPlayer;
