import { useState, useEffect } from "react";
import DOMPurify from "dompurify";

// Custom hook for sanitizing HTML content
const useSanitizeContent = (initialContent) => {
  const [sanitizedContent, setSanitizedContent] = useState(initialContent);

  useEffect(() => {
    const sanitized = DOMPurify.sanitize(initialContent, {
      ALLOWED_ATTR: [
        "target",
        "href",
        "style",
        "src",
        "width",
        "height",
        "alt",
        "title",
        "class",
        "id",
        "data-*",
        "onclick",
        "onmouseover",
        "onmouseout",
        "role",
        "tabindex",
        "for",
        "placeholder",
        "value"
      ]
    });
    setSanitizedContent(sanitized);
  }, [initialContent]);

  return sanitizedContent;
};

export default useSanitizeContent;
